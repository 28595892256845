exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-en-blog-jsx": () => import("./../../../src/pages/en/blog.jsx" /* webpackChunkName: "component---src-pages-en-blog-jsx" */),
  "component---src-pages-en-cart-jsx": () => import("./../../../src/pages/en/cart.jsx" /* webpackChunkName: "component---src-pages-en-cart-jsx" */),
  "component---src-pages-en-courses-jsx": () => import("./../../../src/pages/en/courses.jsx" /* webpackChunkName: "component---src-pages-en-courses-jsx" */),
  "component---src-pages-en-dutch-grammar-jsx": () => import("./../../../src/pages/en/dutch-grammar.jsx" /* webpackChunkName: "component---src-pages-en-dutch-grammar-jsx" */),
  "component---src-pages-en-index-jsx": () => import("./../../../src/pages/en/index.jsx" /* webpackChunkName: "component---src-pages-en-index-jsx" */),
  "component---src-pages-en-live-courses-jsx": () => import("./../../../src/pages/en/live-courses.jsx" /* webpackChunkName: "component---src-pages-en-live-courses-jsx" */),
  "component---src-pages-en-podcast-jsx": () => import("./../../../src/pages/en/podcast.jsx" /* webpackChunkName: "component---src-pages-en-podcast-jsx" */),
  "component---src-pages-en-private-lessons-jsx": () => import("./../../../src/pages/en/private-lessons.jsx" /* webpackChunkName: "component---src-pages-en-private-lessons-jsx" */),
  "component---src-pages-en-shop-jsx": () => import("./../../../src/pages/en/shop.jsx" /* webpackChunkName: "component---src-pages-en-shop-jsx" */),
  "component---src-pages-en-user-jsx": () => import("./../../../src/pages/en/user.jsx" /* webpackChunkName: "component---src-pages-en-user-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-live-courses-jsx": () => import("./../../../src/pages/live-courses.jsx" /* webpackChunkName: "component---src-pages-live-courses-jsx" */),
  "component---src-pages-nederlandse-grammatica-jsx": () => import("./../../../src/pages/nederlandse-grammatica.jsx" /* webpackChunkName: "component---src-pages-nederlandse-grammatica-jsx" */),
  "component---src-pages-podcast-jsx": () => import("./../../../src/pages/podcast.jsx" /* webpackChunkName: "component---src-pages-podcast-jsx" */),
  "component---src-pages-private-lessons-jsx": () => import("./../../../src/pages/private-lessons.jsx" /* webpackChunkName: "component---src-pages-private-lessons-jsx" */),
  "component---src-pages-shop-jsx": () => import("./../../../src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */),
  "component---src-templates-article-index-jsx": () => import("./../../../src/templates/article/index.jsx" /* webpackChunkName: "component---src-templates-article-index-jsx" */),
  "component---src-templates-concept-index-jsx": () => import("./../../../src/templates/concept/index.jsx" /* webpackChunkName: "component---src-templates-concept-index-jsx" */),
  "component---src-templates-course-index-jsx": () => import("./../../../src/templates/course/index.jsx" /* webpackChunkName: "component---src-templates-course-index-jsx" */),
  "component---src-templates-default-page-index-jsx": () => import("./../../../src/templates/defaultPage/index.jsx" /* webpackChunkName: "component---src-templates-default-page-index-jsx" */),
  "component---src-templates-exam-index-jsx": () => import("./../../../src/templates/exam/index.jsx" /* webpackChunkName: "component---src-templates-exam-index-jsx" */),
  "component---src-templates-live-course-index-jsx": () => import("./../../../src/templates/liveCourse/index.jsx" /* webpackChunkName: "component---src-templates-live-course-index-jsx" */),
  "component---src-templates-podcast-episode-index-jsx": () => import("./../../../src/templates/podcastEpisode/index.jsx" /* webpackChunkName: "component---src-templates-podcast-episode-index-jsx" */),
  "component---src-templates-product-index-jsx": () => import("./../../../src/templates/product/index.jsx" /* webpackChunkName: "component---src-templates-product-index-jsx" */)
}

