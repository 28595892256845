import { css } from "styled-components";

const coloredPrimary = css`
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.lighterOne},
    ${(props) => props.theme.primary}
  );
  border: 1px solid ${(props) => props.theme.primary};
`;
const coloredDefault = css`
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.default_lighterTwo},
    ${(props) => props.theme.default_lighterOne}
  );
  color: ${(props) => props.theme.default_darkerTwo};
  border: 1px solid ${(props) => props.theme.default_lighterOne};
`;
const coloredContrast = css`
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.contrast_lighterOne},
    ${(props) => props.theme.contrast_primary}
  );
  border: 1px solid ${(props) => props.theme.contrast_primary};
`;

const borderedDefaultPrimary = css`
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.default_lighterTwo},
    ${(props) => props.theme.default_lighterOne}
  );
  color: ${(props) => props.theme.default_darkerTwo};
  border: 1px solid ${(props) => props.theme.primary};
`;

const flatPrimary = css`
  background: ${(props) => props.theme.primary};
  color: white;
`;
const flatContrast = css`
  background: ${(props) => props.theme.contrast_primary};
  color: white;
`;
const flatDefault = css`
  background: ${(props) => props.theme.default_primary};
  color: black;
`;

const allStyled = {
  primary: coloredPrimary,
  default: coloredDefault,
  contrast: coloredContrast,
  flat: {
    primary: flatPrimary,
    contrast: flatContrast,
    default: flatDefault,
  },
  bordered: {
    default: {
      primary: borderedDefaultPrimary,
    },
  },
};

export default allStyled;
