import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  ButtonPrimary,
  ButtonContrast,
  CardContainer,
  ContentContainer,
  ProductDetails,
  ButtonContainer,
  OverlineTag,
} from "./BookGridItem.css";
import shopInfo from "../../../data/shopInfo";
import { useAppContext } from "../../context/appProvider";
import DownloadComponent from "./components/DownloadComponent";
import { pushImpressionClick } from "../../utils/tagManager";

const BookGridItem = (props) => {
  const {
    title,
    subtitle,
    price,
    numberOfPages,
    image,
    slug,
    studentLevel,
    productId,
    lang,
    location,
  } = props;

  const ctx = useAppContext();
  const { state, dispatch } = ctx;

  const [isBought, set_isBought] = useState(false);
  const [isInCart, set_isInCart] = useState(false);

  useEffect(() => {
    if (
      state &&
      state.user &&
      state.user.purchased &&
      state.user.purchased.find((e) => e.productId === productId)
    ) {
      set_isBought(true);
      return;
    }

    if (
      state &&
      state.cart &&
      state.cart.items &&
      state.cart.items.find((e) => e.id === productId)
    ) {
      set_isInCart(true);
    }
  }, [state?.cart?.items]);

  const trackClick = () => {
    pushImpressionClick({
      productList: [
        {
          id: productId,
          name: title,
          category: `books`,
        },
      ],
      location,
    });
  };

  // const path = process.env.GATSBY_ROOT_URL + urlSnipcart;
  // const imageUrl = image.fixed.images.fallback.src;
  const content = shopInfo[lang];
  return (
    <CardContainer className={isBought ? "purchased" : ""}>
      <div className="image">
        {isBought ? (
          <GatsbyImage image={image.main} alt={"Learn Dutch with " + title} />
        ) : (
          <Link to={slug} onClick={trackClick}>
            <GatsbyImage image={image.main} alt={"Learn Dutch with " + title} />
          </Link>
        )}
      </div>
      <div className="content">
        <ContentContainer>
          <OverlineTag>Digital edition</OverlineTag>

          {isBought ? (
            <h3>{title}</h3>
          ) : (
            <Link to={slug} onClick={trackClick}>
              <h3>{title}</h3>
            </Link>
          )}
          {subtitle ? (
            <span className="series-description">{subtitle}</span>
          ) : null}

          {studentLevel ? (
            <span className="levels-block">
              {content.ui.goodFor}:
              {studentLevel
                ? studentLevel.map((l, i) => <span key={i}>{l.level}</span>)
                : "-"}
            </span>
          ) : null}
          {!isBought && (
            <ProductDetails>
              {numberOfPages ? (
                <li>
                  {content.ui.pages}: <strong>{numberOfPages}</strong>
                </li>
              ) : null}
              {price ? (
                <li>
                  {content.ui.price}: <strong>{price}€</strong>
                </li>
              ) : null}
            </ProductDetails>
          )}
          <ButtonContainer>
            {isBought ? (
              <>
                {/* <ButtonPrimary>See my book</ButtonPrimary> */}
                <DownloadComponent productId={productId}>
                  See my book
                </DownloadComponent>
              </>
            ) : isInCart ? (
              <Link to={"/en/cart"}>
                <ButtonContrast type="button">Go to cart</ButtonContrast>
              </Link>
            ) : (
              <ButtonPrimary
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch({
                    type: "add-to-cart",
                    payload: {
                      id: productId,
                      title: title,
                      image: image.fixed.images.fallback.src,
                      type: "digital-book",
                      slug: slug,
                      price: price,
                    },
                  });
                }}
              >
                {content.ui.addToCart}
              </ButtonPrimary>
            )}

            <Link to={slug} style={{ margin: "12px 8px" }} onClick={trackClick}>
              View details
            </Link>
          </ButtonContainer>
        </ContentContainer>
      </div>
    </CardContainer>
  );
};

export default BookGridItem;
