import React from "react";
// import { graphql } from 'gatsby'
// import CourseGridItem from "../../components/CourseGridItem"
import Layout from "../../components/Layout/Layout";
// import pathBuilder from "../../utils/pathBuilder"
// import * as Styled from './course.styled'
// import ContentfulRenderer from "../../components/System/ContentfulRenderer"
import CourseGridItem from "../../components/CourseGridItem";
import * as Styled from "./styled";

const CoursesTemplate = (props) => {
  const courses = props.data.courses.edges;

  return (
    <Layout environment={props.environment}>
      <Styled.Wrapper>
        <h1>all courses</h1>
        {courses.map((course, i) => (
          <CourseGridItem
            key={i}
            course={course}
            location="video-courses-lister"
          />
        ))}
      </Styled.Wrapper>
    </Layout>
  );
};
export default CoursesTemplate;
