import { theme } from "../../theme/variables";
import styled, { css } from "styled-components";
import mixins from "../../theme/mixins";
import MEDIA from "../../theme/helpers/mediaTemplates";

const Wrapper = styled.div`
  padding: ${theme.halfGutter};
  position: relative;
  margin-top: 40px;
  padding: 48px 32px;
  border-radius: 20px;
  ${mixins.colored.default}
  box-shadow: 5px 5px 7px ${(props) => props.theme.default_darkerOne},
		-2px -2px 4px ${(props) => props.theme.default_lighterTwo};
  ${MEDIA.MINIPHONE`
		padding: 34px 18px;
	`}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  .extra-info {
    p {
      font-size: 0.8em;
    }
  }
`;

const Overline = styled.div`
  text-transform: uppercase;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.default_lighterOne};
  padding-bottom: 16px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  overflow-x: scroll;
  padding: 12px 40px 16px 0px;
  margin-bottom: 18px;
  &::-webkit-scrollbar {
    display: auto;
    background: ${(props) => props.theme.default_lighterTwo};
    height: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.default_primary};
  }
`;

const GroupWrapper = styled.div`
  font-size: 0.8rem;
  background: linear-gradient(
    135deg,
    white,
    ${(props) => props.theme.default_lighterTwo}
  );
  box-shadow: 5px 5px 7px ${(props) => props.theme.default_darkerOne},
    -2px -2px 4px ${(props) => props.theme.default_lighterTwo};
  border-radius: 10px;
  padding: 32px 16px;
  min-width: 200px;
  margin-right: 16px;
  position: relative;
  ${(props) =>
    props.soldOut &&
    css`
      background: ${(props) => props.theme.default_halfUp};
      :after {
        content: "Sold out";
        background: white;
        text-transform: uppercase;
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 5px 8px;
      }
    `}
  ${(props) =>
    props.soldOut &&
    css`
      background: ${(props) => props.theme.default_halfUp};
      color: ${(props) => props.theme.default_darkerOne};
      :after {
        content: "Sold out";
        background: white;
        text-transform: uppercase;
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 5px 8px;
        border-radius: 5px;
      }
    `}
	${(props) =>
    props.isPast &&
    css`
      background: ${(props) => props.theme.default_halfUp};
      color: ${(props) => props.theme.default_darkerOne};
      :after {
        content: "Already started";
        background: white;
        text-transform: uppercase;
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 5px 8px;
        border-radius: 5px;
      }
    `}
`;

const allStyled = {
  Header,
  Wrapper,
  Container,
  Footer,
  GroupWrapper,
  Overline,
};

export default allStyled;
