import styled from "styled-components";
import { theme } from "../../theme/variables";
import MEDIA from "../../theme/helpers/mediaTemplates";

export const CardContainer = styled.div`
  color: ${(props) => props.theme.lighterTwo};
  padding: ${theme.halfGutter} 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border-radius: 10px;
  margin-top: 50px;
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.halfUp},
    ${(props) => props.theme.primary}
  );
  box-shadow: 5px 5px 7px ${(props) => props.theme.default_darkerOne},
    -2px -2px 4px ${(props) => props.theme.lighterOne};

  h1,
  h2,
  h3,
  h4 {
    margin-top: 0px;
    color: ${(props) => props.theme.lighterTwo};

    font-weight: 700;
  }
  p {
    color: white;
  }
  span,
  .overline {
    color: ${(props) => props.theme.lighterTwo};
  }

  ${MEDIA.PHONE`
      flex-direction: column;

      h1,h2,h3 {
          font-size: 20px;
      }
      .overline {
          font-size: 10px;
          

      }
  `};
`;

export const ImageContainer = styled.div`
  width: 40%;
  margin: 0 ${theme.halfGutter};
  border: 10px solid ${(props) => props.theme.lighterTwo};
  border-radius: 8px;

  ${MEDIA.PHONE`
      width: 90%;
  `};
`;

export const ContentContainer = styled.div`
  width: 60%;
  padding: ${theme.halfGutter};
  h3 {
    font-size: 1rem;
  }
  ${MEDIA.PHONE`
      width: 100%;
  `};
`;
