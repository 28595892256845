import { css } from "styled-components";
import MEDIA from "../helpers/mediaTemplates";

const basic = css`
  display: grid;
  grid-template-columns:
    [LB] 1fr [LXL] minmax(11ch, 0.7fr) [LS] repeat(3, [C] 21.66ch)
    [RS] minmax(11ch, 0.7fr) [RXL] 1fr [RB];

  @media (max-width: 768px) {
    grid-template-columns:
      [LB] 1fr [LXL] 1fr [LS] repeat(2, [C] 29ch)
      [RS] 1fr [RXL] 1fr [RB];
  }
  @media (max-width: 568px) {
    grid-template-columns:
      [LB] 1fr [LXL] 1fr [LS] repeat(2, [C] 20ch)
      [RS] 1fr [RXL] 1fr [RB];
  }
  @media (max-width: 440px) {
    grid-template-columns:
      [LB] 1fr [LXL] 1fr [LS] repeat(2, [C] 15ch)
      [RS] 1fr [RXL] 1fr [RB];
  }
`;

const twoColumns = css`
  display: grid;
  grid-template-columns:
    [L] minmax(200px, 560px) [C] minmax(200px, 560px)
    [R];
  ${MEDIA.TABLET`
	
		grid-template-columns:
			[L] minmax(200px, 460px)
			[R];
	
	`}
`;

const centered = css`
  ${basic};
  grid-template-rows: auto 1fr auto 1fr;
`;

const allStyled = {
  basic,
  centered,
  twoColumns,
};

export default allStyled;
