import React from "react";
import { Content } from "./Forms.css";

const SendInBlueForm = () => {
  return (
    <Content>
      <form
        id="sib-form"
        method="POST"
        className="mailchimp_newsletter_form"
        action="https://45ffcecc.sibforms.com/serve/MUIEABvQQdGqOldbulBzNSfyV1e5uYSNKJTS-xnzIeXGu-wo9GMZ3bKkvUw6hez_XaPAxRDXMeC0Ti5EFo-1URE65H9eU7tiSNMAi8mRlNeu_rqKrol_-kBNYD0YnFKxjWPi5qcvGDWItkyqA5dBevplm2uSKWQY9BtSLYqfd7mbYyz2zrZJD7b0GqLwyZlanRfH-0mTfCNjrIRn"
      >
        <label className="entry__label" htmlFor="EMAIL" data-required="*">
          Email
        </label>

        <input
          className="input"
          type="text"
          id="EMAIL"
          name="EMAIL"
          autoComplete="off"
          placeholder="Here goes you email"
          data-required="true"
          required
        />

        <br />

        <label className="entry__label" htmlFor="NOMBRE" data-required="*">
          Name
        </label>

        <input
          className="input"
          maxLength="200"
          type="text"
          id="NOMBRE"
          name="NOMBRE"
          autoComplete="off"
          placeholder="And here your name"
          data-required="true"
          required
        />
        <div className="form-section">
          <label className="entry__label" htmlFor="NOMBRE" data-required="*">
            Do you know your level
          </label>
          <div className="radio-container">
            <label>
              <input
                type="radio"
                name="LEVEL"
                className="input_replaced"
                value="1"
              />
              <span className="radio-button">A1</span>
            </label>

            <label>
              <input
                type="radio"
                name="LEVEL"
                className="input_replaced"
                value="2"
              />
              <span className="radio-button">A2</span>
            </label>

            <label>
              <input
                type="radio"
                name="LEVEL"
                className="input_replaced"
                value="3"
              />
              <span className="radio-button">B1</span>
            </label>
            <label>
              <input
                type="radio"
                name="LEVEL"
                className="input_replaced"
                value="4"
              />
              <span className="radio-button">B2</span>
            </label>
            <label>
              <input
                type="radio"
                name="LEVEL"
                className="input_replaced"
                value="5"
              />
              <span className="radio-button">C1</span>
            </label>
          </div>
        </div>

        <div className="form-section">
          <button
            className="mailchimp_newsletter_form"
            form="sib-form"
            type="submit"
          >
            Join now
          </button>
          <span className="underbutton-msg">Free, cancel anytime</span>
          <input type="hidden" name="locale" value="es" />
          <input type="hidden" name="html_type" value="simple" />
        </div>
      </form>
    </Content>
  );
};
export default SendInBlueForm;
