import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import * as Styled from "./CourseGridItem.styled";
import pathBuilder from "../../utils/pathBuilder";
import Button from "../UI/Button";
import { useAppContext } from "../../context/appProvider";
import { pushImpressionClick } from "../../utils/tagManager";

const CourseGridItem = (props) => {
  const { title, subtitle, price, intro, id, slug, cover } = props.course;

  const ctx = useAppContext();

  const { state, dispatch } = ctx;

  const [isBought, set_isBought] = useState(false);
  const [isInCart, set_isInCart] = useState(false);

  useEffect(() => {
    if (
      state.user.purchased &&
      state.user.purchased.find((e) => e.productId === id)
    ) {
      set_isBought(true);
      return;
    }

    if (
      state &&
      state.cart &&
      state.cart.items &&
      state.cart.items.find((e) => e.productId === id)
    )
      set_isInCart(true);
  }, []);

  const trackClick = () => {
    pushImpressionClick({
      productList: [
        {
          id: id,
          name: title,
          category: `video-course`,
        },
      ],
      location: props.location,
    });
  };

  if (title && price && id && slug && cover)
    return (
      <Styled.Wrapper>
        <Link
          to={pathBuilder("en", "ContentfulCourses", slug ? slug : "test")}
          onClick={trackClick}
        >
          <Styled.TileImage
            as={GatsbyImage}
            image={cover.gatsbyImageData}
            alt={`${title}-cover`}
          />
        </Link>
        <Styled.Container>
          <Styled.Overline>{subtitle}</Styled.Overline>
          <Link
            to={pathBuilder("en", "ContentfulCourses", slug ? slug : "test")}
            onClick={trackClick}
          >
            <h3 className="title">{title}</h3>
          </Link>
          <p className="description">{intro}</p>
          <Styled.Footer>
            {isBought ? (
              <>
                <Button.ContrastPrimary>Continue course</Button.ContrastPrimary>
              </>
            ) : isInCart ? (
              <>
                <Button.ContrastPrimary>Go to checkout</Button.ContrastPrimary>
              </>
            ) : (
              <>
                <span>{price}</span>
                <Button.ContrastPrimary
                  onClick={() =>
                    dispatch({
                      type: "add-to-cart",
                      payload: {
                        id: id,
                        title: title,
                        type: "video-course",
                        slug: pathBuilder("en", "ContentfulCourses", slug),
                        price: price,
                      },
                    })
                  }
                >
                  Add to cart
                </Button.ContrastPrimary>
              </>
            )}
          </Styled.Footer>
        </Styled.Container>
      </Styled.Wrapper>
    );

  // eslint-disable-next-line no-console
  console.log("course grid item was called without correct props", props);
  return null;
};

export default CourseGridItem;
