import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
// import { Button } from "../UI";
import * as Styled from "./styled";
import { pushEvent } from "../../utils/tagManager";
/**
 * renders login / signup / logout / your account button
 * @return {Component}
 */

const LoginButton = (props) => {
  const { loginWithRedirect } = useAuth0();
  const { message = "login" } = props;

  return (
    <div className="c-login-button">
      <Styled.LoginButton
        onClick={(e) => {
          e.preventDefault();
          pushEvent({
            event: "interaction",
            action: "start-login",
            location: props.location,
          });
          loginWithRedirect(window.location.origin);
        }}
        type={props.type}
      >
        {message}
      </Styled.LoginButton>
    </div>
  );
};

export default LoginButton;
