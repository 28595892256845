const indexInfo = {
  en: {
    seo: {
      title: `Learn Dutch for free with The Dutch Online Academy.`,
      description: `We are making learning dutch online universal, accessible, comfortable and cheap. And we have just started.`,
    },
    title: `Introducing, The Dutch Online Academy. 
We help you reach your language goals. 
And we've just started.`,
    subscribeTitle: `Practice, every week`,
    subscribeSubtitle: `
			Leave your email to start improving your Dutch regularly with our week's most interesting content for free. Cancel anytime.
		`,
    serviceListTitle: `Combine as you want and learn Dutch online`,
    selfLearnTeaser: `Learn Grammar`,
    selfLearnSlug: `/en/dutch-grammar`,
    privateLessonsTeaser: `Get private lessons`,
    privateLessonsSlug: `/en/private-lessons`,
    // videoCoursesTeaser: `Check our video courses`,
    // videoCoursesSlug: `https://app.thedutchonlineacademy.com`,
    podcastTeaser: `Listen to the podcast`,
    podcastSlug: `/en/podcast`,
    exercisePdfsTeaser: `Exercise Pdfs`,
    exercisePdfsSlug: `/en/shop`,
  },
  nl: {
    seo: {
      title: `Leer gratis Nederlands met The Dutch Online Academy.`,
      description: `We maken leren Nederlands universeel, toegankelijk, comfortabel en goedkoop`,
    },
    title: `Wij zijn The Dutch Online Academy. 
We helpen jou je doelen te bereiken.
En we zijn pas net begonnen`,
    subscribeTitle: `Practice, every week`,
    subscribeSubtitle: `
		Laat je e-mail achter en we sturen je elke week GRATIS materialen om Nederlands te leren. Uitschrijven wanneer je wilt. 
        `,
    serviceListTitle: `Combine as you want and learn Dutch online`,
    selfLearnTeaser: `Zelfstudie`,
    selfLearnSlug: `/nederlandse-grammatica`,
    privateLessonsTeaser: `Privéles`,
    privateLessonsSlug: `/private-lessons`,
    podcastTeaser: `Podcast`,
    podcastSlug: `/podcast`,
    exercisePdfsTeaser: `Oefeningen Pdfs`,
    exercisePdfsSlug: `/shop`,
    // videoCoursesTeaser: `Video courses`,
    // videoCoursesSlug: `https://app.thedutchonlineacademy.com`,
  },
};

export default indexInfo;
