import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { useAppContext } from "../../context/appProvider";
import { Link } from "gatsby";
import * as Styled from "./styled";
import { postData } from "../../utils/helpers";
import { pushGoToCheckout } from "../../utils/tagManager";
import CartLineItem from "./CartLineItem";
import { Button } from "../../components/UI/Buttons";
import Ideal from "../../assets/icons/Ideal.svg";
import Bancontact from "../../assets/icons/Bancontact.svg";
import Sofort from "../../assets/icons/Sofort.svg";
import Visa from "../../assets/icons/Visa.svg";
import Mastercard from "../../assets/icons/Mastercard.svg";

const CartTemplate = (props) => {
  const ctx = useAppContext();
  const { state, dispatch, getAccessTokenSilently } = ctx;
  const { environment } = props;
  const [message, set_message] = useState({ message: "", type: "" });
  const [tracked, set_tracked] = useState(false);
  const initCheckout = async (event) => {
    pushGoToCheckout({
      step: 2,
      productList: state.cart.items.map((el) => ({
        id: el.id,
        name: el.title,
        category: el.type,
        quantity: 1,
      })),
    });

    try {
      let response;
      if (state?.user?.authenticated) {
        const token = await getAccessTokenSilently();
        response = await postData({
          url: `${process.env.GATSBY_BACKEND_URL}/checkout/create-session`,
          data: {
            cart: state.cart,
          },
          token,
        });
      } else {
        response = await postData({
          url: `${process.env.GATSBY_BACKEND_URL}/checkout/open-create-session`,
          data: {
            cart: state.cart,
          },
        });
      }
      if (response.session.url) {
        window.location.href = response.session.url;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("response") !== "success") {
      if (state.cart.total && !tracked) {
        pushGoToCheckout({
          step: 1,
          option: "cart-page",
          productList: state.cart.items.map((el) => ({
            id: el.id,
            name: el.title,
            category: el.type,
            quantity: 1,
            price: el.price,
          })),
        });
        set_tracked(true);
      }
    }
  }, [state?.cart]);

  const seo = {};
  seo.title = `Cart - The Dutch Online Academy`;
  environment.seo = seo;

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("startCheckout") === "true" && state.cart.count > 0) {
      initCheckout();
    }
  }, [state?.cart?.count]);
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (state?.cart?.total) {
      if (query.get("response") === "success") {
        dispatch({
          type: "clean-cart",
        });
        set_message({
          message: "Order placed! You will receive an email confirmation.",
          type: "success",
        });
        setTimeout(() => {
          window.location.href = "/en/user";
        }, 2000);
      }

      if (query.get("response") === "failure") {
        set_message({
          message:
            "Your order didn't go through, if it is not what you were expecting, ",
          type: "failure",
        });
      }
    }
  }, [state?.cart?.total]);

  return (
    <Layout environment={environment}>
      <Styled.Wrapper>
        <Styled.Container>
          {message.message && (
            <Styled.Notification type={message.type}>
              {message.message}
              {message.type === "failure" && (
                <a href="mailto:info@thedutchonlineacademy.com">contact us</a>
              )}
            </Styled.Notification>
          )}
          <h1>Shopping cart</h1>
          {state && state.cart && state.cart.items?.length > 0 ? (
            <Styled.Table>
              <thead>
                <Styled.TableRow className="heading">
                  <th className="title" style={{ textAlign: "left" }}>
                    Name
                  </th>
                  <th className="price">Price</th>
                  <th className="remove-from-cart"></th>
                </Styled.TableRow>
              </thead>
              <tbody>
                {state.cart.items.map((lineItem) => (
                  <CartLineItem lineItem={lineItem} key={lineItem.id} />
                ))}
              </tbody>
            </Styled.Table>
          ) : (
            <div>
              <h3>Your cart is empty</h3>

              <Link to={"/en/shop"}>
                <h5>
                  Add any product in here <u>here</u>
                </h5>
              </Link>
            </div>
          )}
          {state?.cart?.total > 0 && (
            <>
              <Styled.TableFooter>
                <small> ({state?.cart?.count} items) </small>
                {state?.cart?.total} €
              </Styled.TableFooter>
              <Styled.TableFooter style={{ width: "100%", marginTop: "20px" }}>
                <Button
                  onClick={async () => {
                    initCheckout();
                  }}
                >
                  Checkout
                </Button>
              </Styled.TableFooter>
            </>
          )}

          <Styled.PaymentMethodsRow>
            <p className="info">
              Need help? Write us an email to{" "}
              <a href="mailto:info@thedutchonlineacademy.com">
                info@thedutchonlineacademy.com
              </a>
            </p>
            <ul className="snipcart-featured-payment-methods__list">
              <li className="snipcart-featured-payment-methods__list-item">
                <Ideal className="snipcart__icon--brand" />
              </li>
              <li className="snipcart-featured-payment-methods__list-item">
                <Visa className="snipcart__icon--brand" />
              </li>
              <li className="snipcart-featured-payment-methods__list-item">
                <Mastercard className="snipcart__icon--brand" />
              </li>
              {/* <li className="snipcart-featured-payment-methods__list-item">
                <Sofort className="snipcart__icon--brand" />
              </li> */}
              <li className="snipcart-featured-payment-methods__list-item">
                <Bancontact className="snipcart__icon--brand" />
              </li>
            </ul>
          </Styled.PaymentMethodsRow>
        </Styled.Container>
      </Styled.Wrapper>
    </Layout>
  );
};

export default CartTemplate;
