import { createGlobalStyle } from "styled-components";
import { theme } from "../../theme/variables";
import MEDIA from "../../theme/helpers/mediaTemplates";

const GlobalStyle = createGlobalStyle`
    html{
        scroll-behavior: smooth;
    }
    html,body{
        margin: 0px;
        padding: 0px;
        background:  ${(props) => props.theme.default_lighterTwo};
    }
    html,body,h4,h5,p{
        font-size: 1rem;
    }
    :root{
        font-size: ${theme.fontBaseSize};
        ${MEDIA.MINIPHONE`
        font-size: ${theme.fontMobileBaseSize};
        `}
    }

    h1,h2,h3,h4,h5 {
        font-family: ${theme.fontHeaders};
        text-align: left;
    }
    h1,h2,h3,h4,h5,p,li,ul,ol{
        color: ${theme.colorSystemDarkest};
        font-weight: 400;
    }
    ul,ol{
        li {
            line-height: 2;
        }
    }
    h1{
        font-size: ${theme.fontH1Size};
        font-weight: 700;
        margin-top: 0px;
    }
    h2{
        font-size: ${theme.fontH2Size};
        font-weight: 700;

    }
    h3{
        font-size: ${theme.fontH3Size};
        font-weight: 700;

    }
    p{
        line-height: ${theme.fontPLineHeight};
        font-weight: 300;
        a {
            border-bottom: 2px solid ${theme.colorPrimary};
            font-family: ${theme.fontBody};
        }
        a:hover{
            color: ${theme.colorSecondaryDark};
        }
    }
    body {
        font-family: ${theme.fontBody};
        /* padding-top: ${theme.headingHeight}; */
        /* padding-bottom: 100vh;*/
        #main{
            

        } 
    }
    a{
        color: ${theme.colorSystemDarkest};
        text-decoration:none;
        font-family: ${theme.fontHeaders};
    }
    .overline{
        text-transform: uppercase;
        letter-spacing: 1.5px;
        font-size: ${theme.fontOverlineSize};
        color: ${(props) => props.theme.default_darkerTwo};;
        font-family: ${theme.fontHeaders};
    }

    .miniText{
        font-size: ${theme.fontMiniSize};
        color: ${theme.colorSecondaryDark};
        line-height: 2;
        
        
    }
    *::selection {
        background: ${theme.colorSecondaryLight};
    }
    .subtitle{
        font-size: ${theme.fontSubtitleSize};
        font-family: ${theme.fontHeaders};
        color: ${theme.colorSecondaryDark};
        text-transform: uppercase;
    }
    *{
        box-sizing: border-box;
    }
    

    
    div{
        box-sizing: border-box;
    }
    ${MEDIA.PHONE`
        .snipcart-item-line__header{
            .snipcart-item-line__media{
                    display:none;
            }
    }
    `}
    .snipcart-discount-box{
        display: none!important;
    }

    
`;

export default GlobalStyle;
