import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import * as Styled from "./ArticleGridItem.styled";
import pathBuilder from "../../utils/pathBuilder";

const ArticleGridItem = (props) => {
  const { title, slug, node_locale, __typename, image, description } =
    props.node;

  return (
    <Link to={pathBuilder(node_locale, __typename, slug)}>
      <Styled.CardContainer>
        <Styled.ImageContainer>
          <GatsbyImage image={image.main} />
        </Styled.ImageContainer>
        <Styled.ContentContainer>
          <h2>{title}</h2>
          {description && <p>{description.childMarkdownRemark.excerpt}</p>}
        </Styled.ContentContainer>
      </Styled.CardContainer>
    </Link>
  );
};

export default ArticleGridItem;
