import React from "react";
import { graphql } from "gatsby";
import LiveCoursesTemplate from "../templates/LiveCoursesTemplate";

const environment = {
  lang: "en",
  path: "/en/dutch-grammar",
  altHrefLang: [
    {
      lang: "en",
      path: "/en/live-courses",
    },
  ],

  theme: "pink",
};

const LiveCourses = (props) => {
  return <LiveCoursesTemplate {...props} environment={environment} />;
};
export default LiveCourses;

export const OnlineCoursesQuery = graphql`
  query onlineLiveCoursesNL {
    courses: allContentfulLiveOnlineCourses(
      filter: { node_locale: { eq: "en" } }
      sort: { fields: [priority], order: DESC }
    ) {
      edges {
        node {
          title
          slug
          price
          contentful_id
          node_locale
          __typename
          subtitle
          studentLevel {
            level
            levelShorthand
          }

          description {
            raw
          }
          intro {
            childMarkdownRemark {
              html
            }
          }
          groups {
            id
            starting(locale: "nl")
            title
            soldOut
          }
          teachers {
            title
            description {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`;
