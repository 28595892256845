import React, { useState } from "react";
import * as Styled from "./Header.styled";
import SideNavigation from "./SideNavigation";
import Logo from "./Logo";
import HeaderConfig from "../../../data/HeaderConfig";
import { Link } from "gatsby";
// import HelloBar from "./HelloBar";
import { useAppContext } from "../../context/appProvider";
import { StaticQuery, graphql } from "gatsby";
import LanguageSwitcher from "./LanguageSwitcher";
import { Menu } from "styled-icons/boxicons-regular";
// import { Button } from "../UI";
import Cart from "./Cart";
import pathBuilder from "../../utils/pathBuilder";
import LoginButton from "../LoginButton";

const HeaderComponent = (parentProps) => {
  const { props, data } = parentProps;
  const { altHrefLang, lang } = props.environment;
  // const { lang } = props.environment;
  const [sideNavigationOpen, set_sideNavigationOpen] = useState(false);
  const sideNavigationToggleClickHandler = () => {
    set_sideNavigationOpen(!sideNavigationOpen);
  };
  const ctx = useAppContext();
  const user = ctx?.state?.user;

  const homeLink = HeaderConfig.indexPath[lang];
  let sideNavigationClasses = "sideNavigation";
  if (sideNavigationOpen) {
    sideNavigationClasses = "sideNavigation open";
  }
  const menu = data.menu.edges.find(
    (edge) => edge.node.node_locale === lang,
  ).node;

  return (
    <>
      {/* {user?.authenticated ? null : (
        <HelloBar environment={props.environment} />
      )} */}

      <Styled.HeaderWrapper id="header-wrapper">
        <Styled.HeaderContainer id="header-container">
          <Menu
            onClick={sideNavigationToggleClickHandler}
            onKeyDown={sideNavigationToggleClickHandler}
            className="header__menu-hamburger"
          />
          <Logo indexLink={homeLink} id="main-logo" />
          {altHrefLang.length > 1 && (
            <LanguageSwitcher lang={lang} altHrefLang={altHrefLang} />
          )}
          <Styled.HeaderContent>
            <Styled.Navigation id="main-navigation">
              {menu?.links?.map((link, index) => (
                <li key={index}>
                  {link.internalLink && link.internalLink.slug ? (
                    <Link
                      to={pathBuilder(
                        lang,
                        "ContentfulPages",
                        link.internalLink.slug,
                        link.internalLink.reservedPaths,
                      )}
                      activeClassName="active"
                    >
                      {link.title}
                    </Link>
                  ) : (
                    <a href={link.externalUrl}>{link.title}</a>
                  )}
                </li>
              ))}
            </Styled.Navigation>

            {user?.authenticated ? (
              <Link
                className="my-account"
                to={pathBuilder("en", "", "", "user")}
              >
                My account
              </Link>
            ) : (
              <LoginButton location="header-component" />
            )}
            <Cart lang={lang} className={"header__menu-cart"} />
          </Styled.HeaderContent>
        </Styled.HeaderContainer>
      </Styled.HeaderWrapper>
      <SideNavigation
        lang={lang}
        classes={sideNavigationClasses}
        onClickFunction={sideNavigationToggleClickHandler}
        navMenu={menu.links}
      />
    </>
  );
};

const Header = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query allContentfulpages {
          menu: allContentfulMenu(filter: { location: { eq: "header-menu" } }) {
            edges {
              node {
                title
                location
                __typename
                links {
                  title
                  internalLink {
                    slug
                    node_locale
                    __typename
                    reservedPaths
                  }
                  externalUrl
                }
                node_locale
              }
            }
          }
        }
      `}
      render={(data) => {
        return <HeaderComponent data={data} props={props} />;
      }}
    />
  );
};

export default Header;
