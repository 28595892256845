import React from "react";
import { graphql } from "gatsby";
// import CourseGridItem from "../../components/CourseGridItem"
import Layout from "../../components/Layout/Layout";
import pathBuilder from "../../utils/pathBuilder";
import * as Styled from "./course.styled";
import ContentfulRenderer from "../../components/System/ContentfulRenderer";

const CoursesTemplate = (props) => {
  // console.log(props.data);
  const {
    content,
    node_locale,
    intro,
    slug,
    seoDescription,
    seoTitle,
    title,
    presentationVideoLink,
    contentful_id,
    __typename,
  } = props.data.course;

  const environment = {
    lang: node_locale,
    id: contentful_id,
    path: pathBuilder(node_locale, __typename, slug),
    // imgUrl: image.localFile.url,
    theme: "pink",
    altHrefLang: [
      {
        lang: node_locale,
        path: pathBuilder(node_locale, __typename, slug),
      },
    ],
  };
  const seo = {};
  if (seoDescription) {
    seo.description = seoDescription;
  }
  if (seoTitle) {
    seo.title = seoTitle;
  } else {
    seo.title = title;
  }
  environment.seo = seo;

  const videoId = presentationVideoLink.split("/");
  const accountId = presentationVideoLink.split("/");

  return (
    <Layout environment={environment}>
      <Styled.Wrapper>
        <h1>{title}</h1>
        <div>
          <p>{intro}</p>
        </div>
        <p>{JSON.stringify(presentationVideoLink, false, 2)}</p>

        <iframe
          src={`https://player.vimeo.com/video/${accountId[accountId.length - 2]
            }?h=${videoId[videoId.length - 1]
            }&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Het inburgeringsexamen schrijven"
        ></iframe>

        <script src="https://player.vimeo.com/api/player.js"></script>

        <ContentfulRenderer json={content} lang={environment.lang} />
      </Styled.Wrapper>
    </Layout>
  );
};

export const courseQuery = graphql`
  query courseQuery($lang: String!, $id: String!) {
    course: contentfulCourses(
      contentful_id: { eq: $id }
      node_locale: { eq: $lang }
    ) {
      slug
      __typename
      title
      intro
      showFaq
      node_locale
      presentationVideoLink
      lessons {
        title
      }
      contentfulid
      contentful_id
      content {
        raw
      }
    }
  }
`;

export default CoursesTemplate;
