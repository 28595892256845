import React from "react";
import moment from "moment";
import Styled from "../styled";

export default function GroupCard({ group }) {
  var date = moment(group.starting);
  var now = moment().add(-1, "days");

  const isSoldOut = group.soldOut;
  const isPast = !isSoldOut && now > date ? true : false;

  return (
    <Styled.GroupWrapper soldOut={isSoldOut} isPast={isPast}>
      <h4>{group.title}</h4>
      {group.starting ? (
        <span>
          Starting on: {moment(group.starting).format("dddd, MMMM Do")}
        </span>
      ) : (
        <span>View more info</span>
      )}
    </Styled.GroupWrapper>
  );
}
