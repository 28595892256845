import React, { useState, useEffect } from "react";
import moment from "moment";
import Styled from "../styled";
import { Button, Tag } from "../../../components/UI/index";
import dataStudio from "../../../utils/dataStudio";
import { useAppContext } from "../../../context/appProvider";
import pathBuilder from "../../../utils/pathBuilder";
import { Link } from "gatsby";

const GroupCard = (props) => {
  const [isBought, set_isBought] = useState(false);
  const [isInCart, set_isInCart] = useState(false);
  const { group, course, cover, price, contentful_id, slug } = props;
  const ctx = useAppContext();
  const { state, dispatch } = ctx;

  const contactUrl = `mailto:info@thedutchonlineacademy.com?body=${encodeURI(
    `Hello! I was seeing the course:${course}, group ${group.title}, starting on ${group.starting} and I wanted to...`,
  )}&subject=${encodeURI(`Joining / question about course ${course}`)}`;

  const date = moment(group.starting);
  const endDate = moment(group.starting);
  const now = moment().add(-1, "days");

  const isPast = now > date ? true : false;
  const isFinished = now > endDate ? true : false;

  useEffect(() => {
    if (
      state &&
      state.user &&
      state.user.purchased &&
      state.user.purchased.find((e) => e.productId === contentful_id)
    ) {
      set_isBought(true);
      return;
    }

    if (
      state &&
      state.cart &&
      state.cart.items &&
      state.cart.items.find((e) => e.id === contentful_id)
    ) {
      set_isInCart(true);
    }
  }, [state?.cart?.items]);

  return (
    <Styled.GroupWrapper>
      <Styled.Body>
        {!group.soldOut && !isPast && (
          <Tag.Primary>Still a spot available</Tag.Primary>
        )}
        <h4>{group.title}</h4>

        {group.starting && (
          <span className="block">
            <strong className="overline">Starting on</strong>:{" "}
            {moment(group.starting).format("dddd, MMMM Do")}
          </span>
        )}
        {group.ending && (
          <span className="block">
            <strong className="overline">Until</strong>:{" "}
            {moment(group.ending).format("dddd, MMMM Do")}
          </span>
        )}
        <span className="block price">
          <strong className="overline">Price</strong>: {price}€
        </span>
      </Styled.Body>
      <Styled.Body centered={true}>
        {group.soldOut ? (
          <Button.Disabled>Fully booked</Button.Disabled>
        ) : isFinished ? (
          <Button.Disabled>Finished</Button.Disabled>
        ) : isPast ? (
          <Button.Disabled>Already ongoing</Button.Disabled>
        ) : isBought ? (
          <Button.Disabled>Already bought</Button.Disabled>
        ) : isInCart ? (
          <Link to={"/en/cart"}>
            <Button.ContrastPrimary>Finish your order</Button.ContrastPrimary>
          </Link>
        ) : (
          <>
            <Button.PrimaryPrimary
              onClick={() => {
                dispatch({
                  type: "add-to-cart",
                  payload: {
                    id: contentful_id,
                    title: `${course} - ${group.title}`,
                    type: "live-course",
                    image: cover?.fixed?.src,
                    slug: pathBuilder(
                      "en",
                      "ContentfulLiveOnlineCourses",
                      slug,
                    ),
                    price: price,
                  },
                });
              }}
            >
              Add to cart
            </Button.PrimaryPrimary>
            <a
              href={contactUrl}
              onClick={() =>
                dataStudio({
                  action: "contact_group_courses",
                  course: course,
                  group: group.title,
                  starting: group.starting,
                })
              }
            >
              or <u>ask a question</u>
            </a>
          </>
        )}
      </Styled.Body>
    </Styled.GroupWrapper>
  );
};

export default GroupCard;
