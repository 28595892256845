import React, { useEffect } from "react";
import UpcomingCourses from "./components/UpcomingCourses";
import groupLessonsData from "../../../data/groupLessonsData";
import PageIntro from "../../components/PageIntro/PageIntro";
import Layout from "../../components/Layout/Layout";
import LiveCourseGridItem from "../../components/LiveCourseGridItem";
import SectionFaq from "../../components/SectionFaq";
import Styled from "./styled";
import Testimonials from "../../components/testimonails";
import { pushImpressionView } from "../../utils/tagManager";

const LiveCoursesTemplate = (props) => {
  const { courses } = props.data;
  const { environment } = props;
  const content = groupLessonsData[environment.lang].hero;
  const contentSeo = groupLessonsData[environment.lang].seo;
  const contentFaq = groupLessonsData[environment.lang].faq;
  const seo = {};
  if (contentSeo.description) {
    seo.description = contentSeo.description;
  }
  if (contentSeo.title) {
    seo.title = contentSeo.title;
  } else {
    seo.title = content.title;
  }
  environment.seo = seo;

  useEffect(() => {
    pushImpressionView({
      productList: courses.edges.map((course) => ({
        id: course.node.contentful_id,
        name: course.node.title,
        category: `live-course`,
        price: course.price,
      })),
      location: "live-course-lister",
    });
  }, []);

  return (
    <Layout environment={environment}>
      <PageIntro>
        <div className="container">
          <h1>{content.title}</h1>
          <p>{content.subtitle}</p>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: `<div style="padding:75% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/682468149?h=dbeae72bcd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="group courses video_1"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>`,
          }}
        />
        <div style={{ display: "flex" }}>
          <a href="#testimonials" style={{ padding: "8px", fontSize: "14px" }}>
            Student reviews
          </a>
          <a href="#FAQ" style={{ padding: "8px", fontSize: "14px" }}>
            Frequently asked questions
          </a>
        </div>
      </PageIntro>

      <Styled.Wrapper>
        <UpcomingCourses courses={courses} />

        <Styled.Container>
          {courses.edges.map(({ node }, index) => (
            <LiveCourseGridItem
              key={index}
              node={node}
              location="live-courses-lister"
            />
          ))}
        </Styled.Container>
      </Styled.Wrapper>
      <Testimonials />

      <SectionFaq content={contentFaq} />
    </Layout>
  );
};
export default LiveCoursesTemplate;
