// import { Price } from "types";

// export const getURL = () => {
//   const url =
//     process?.env?.URL && process.env.URL !== ""
//       ? process.env.URL
//       : process?.env?.VERCEL_URL && process.env.VERCEL_URL !== ""
//       ? process.env.VERCEL_URL
//       : "http://localhost:3000";
//   return url.includes("http") ? url : `https://${url}`;
// };

export const postData = async ({ url, token, data }) => {
  // console.log("posting,", url, token, data);

  const res = await fetch(url, {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json", token }),
    credentials: "same-origin",
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    console.log("Error in postData", { url, token, data, res });

    throw Error(res.statusText);
  }

  return res.json();
};

export const getData = async ({ url, token, data }) => {
  console.log("posting,", url, token, data);

  const res = await fetch(url, {
    method: "GET",
    headers: new Headers({ "Content-Type": "application/json", token }),
    credentials: "same-origin",
  });

  if (!res.ok) {
    console.log("Error in getData", { url, token, data, res });
    throw Error(res.statusText);
  }

  return res.json();
};

// export const toDateTime = (secs: number) => {
//   var t = new Date("1970-01-01T00:30:00Z"); // Unix epoch start.
//   t.setSeconds(secs);
//   return t;
// };
