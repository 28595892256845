// import rmvObjInArr from "../../utils/rmvObjInArr";

function userReducer(state, action) {
  switch (action.type) {
    case "authenticated-user": {
      return {
        ...state,
        authenticated: true,
        user: action.payload,
      };
    }
    case "user-data-received": {
      return {
        ...state,
        purchased: action?.payload?.purchased,
        invoices: action?.payload?.invoices,
        // authenticated: action?.payload?.userId ? true : false,
      };
    }
    case "logout": {
      return {
        ...state,
        user: {},
        purchased: [],
        authenticated: false,
      };
    }
    default: {
      return state;
    }
  }
}

export default userReducer;
