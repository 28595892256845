import React from "react";
import Layout from "../../components/Layout/Layout";
// import Img from "gatsby-image"
import { Hero, SubscribeCard, ServiceList } from "./index.css";
// import MailchimpSimpleForm from "../../components/Forms/MailchimpSimpleForm"
import SendInBlueForm from "../../components/Forms/SendInBlueForm";

import { Link } from "gatsby";
import indexInfo from "../../../data/indexInfo";
import ExercisePdfs from "../../assets/icons/index/exercise-pdfs.svg";
import Grammar from "../../assets/icons/index/grammar.svg";
import Podcast from "../../assets/icons/index/podcast.svg";
import Videos from "../../assets/icons/index/videos.svg";
// import PrivateLessons from "../../assets/icons/index/private-lessons.svg"
import { OrganizationSchema } from "../../components/Seo/Schemas";

const IndexTemplate = (props) => {
  const { environment } = props;
  // const sources = [
  // 	{
  // 		...data.imgMobile.childImageSharp.fluid,
  // 	},
  // 	{
  // 		...data.imgDesktop.childImageSharp.fluid,
  // 		media: `(min-width: 800px)`,
  // 	},
  // ]
  const content = indexInfo[environment.lang];

  const seo = {};
  if (content.seo.description) {
    seo.description = content.seo.description;
  }
  if (content.seo.title) {
    seo.title = content.seo.title;
  } else {
    seo.title = content.hero.title;
  }
  environment.seo = seo;

  return (
    <Layout environment={environment}>
      <OrganizationSchema />
      <Hero>
        <div className="content">
          <h1>{content.title}</h1>
        </div>
        <SubscribeCard id="subscribeCard">
          <h2>{content.subscribeTitle}</h2>
          <h4>{content.subscribeSubtitle}</h4>
          <SendInBlueForm formTitle="welcome" />
        </SubscribeCard>
      </Hero>
      <ServiceList>
        <div className="content">
          <h2>{content.serviceListTitle}</h2>

          <div className="services">
            {/* <Link
								to={content.privateLessonsSlug}
								className="item"
							>
								<div className="icon">
									<PrivateLessons />
								</div>
								<span>{content.privateLessonsTeaser}</span>
							</Link> */}

            <Link to={content.selfLearnSlug} className="item">
              <div className="icon">
                <Grammar />
              </div>
              <span>{content.selfLearnTeaser}</span>
            </Link>
            <Link to={content.podcastSlug} className="item">
              <div className="icon">
                <Podcast />
              </div>
              <span>{content.podcastTeaser}</span>
            </Link>
            <Link to={content.exercisePdfsSlug} className="item">
              <div className="icon">
                <ExercisePdfs />
              </div>
              <span>{content.exercisePdfsTeaser}</span>
            </Link>
          </div>
        </div>
      </ServiceList>
    </Layout>
  );
};

export default IndexTemplate;
