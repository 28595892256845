import React from "react";
import { Link } from "gatsby";
import EnFlag from "../../assets/icons/flag-uk.svg";
import NlFlag from "../../assets/icons/flag-nl.svg";
import { LanguageSwitcherContainer } from "./Header.styled";

const LanguageSwitcher = (props) => {
  const languageSwitchEvent = () => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: "languageSwitched",
      eventCategory: "headerButton",
      eventAction: "switch",
    });
  };

  const lang = props.lang;
  const altHrefLang = props.altHrefLang;
  function newFunction(item) {
    return (
      <Link
        to={item.path}
        key={item.lang}
        className={"currentLang_" + lang + " langTag_" + item.lang}
        onClick={languageSwitchEvent}
      >
        {item.lang === "en" ? <span>EN</span> : <span>NL</span>}
      </Link>
    );
  }

  return (
    <LanguageSwitcherContainer id="languageSwitcher" className="headerIcon">
      {altHrefLang ? altHrefLang.map((item) => newFunction(item)) : null}
    </LanguageSwitcherContainer>
  );
};

export default LanguageSwitcher;
