import React from "react";
import FooterConfig from "../../../data/FooterConfig";
// import Whatsapp from "../../assets/icons/share/whatsapp.svg"
import Facebook from "../../assets/icons/share/facebook.svg";
// import Email from "../../assets/icons/share/email.svg"
import Instagram from "../../assets/icons/instagram.svg";
import Youtube from "../../assets/icons/youtube.svg";
import { StaticQuery, graphql } from "gatsby";
import { FooterContainer, FooterNavigation } from "./Footer.css";
import { Link } from "gatsby";
import pathBuilder from "../../utils/pathBuilder";

class Footer extends React.Component {
  shareEventLaunch = () => {
    window.dataLayer.push({
      event: "shareButtonClick",
      eventCategory: "footerButton",
      eventAction: "share",
      // language: lang
      // pageType: pageType,
      // destination: destination
    });
  };
  render() {
    const lang = this.props.environment.lang;
    const titleOne = FooterConfig.titlePrimaryMenu[lang];
    const navMenu = FooterConfig.primaryMenu[lang];
    const thirdMenu = FooterConfig.thirdMenu[lang];
    const copyright = FooterConfig.copyright[lang];
    const feedback = FooterConfig.feedback[lang];
    const titleSocial = FooterConfig.titleSocialMenu[lang];
    // const titleThree = FooterConfig.titleThirdMenu[lang]
    const {
      enCornerstoneArticles,
      nlCornerstoneArticles,
      enCornerstonePills,
      nlCornerstonePills,
    } = this.props.data;
    let menu = enCornerstoneArticles.edges.concat(enCornerstonePills.edges);

    if (lang === "nl") {
      menu = nlCornerstoneArticles.edges.concat(nlCornerstonePills.edges);
    }
    return (
      <FooterContainer>
        <div className="placeholder"></div>
        <div id="footer-cta">
          <div className="content">
            <div className="column">
              <nav>
                <span className="overline">Top stories</span>
                <ul>
                  {menu.map(({ node }, index) => (
                    <li key={index}>
                      <Link
                        to={pathBuilder(
                          node.node_locale,
                          node.__typename,
                          node.slug
                        )}
                        activeClassName="active"
                      >
                        {node.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
            <div className="column">
              <nav>
                <span className="overline">{titleOne}</span>
                <ul>
                  {navMenu.map((item, index) => (
                    <li key={index}>
                      <Link to={item.address} activeClassName="active">
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
            <div className="column">
              <nav>
                <span className="overline">{titleSocial}</span>
                <div className="buttons">
                  <a
                    href="https://facebook.com/thedutchonlineacademy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Facebook />
                  </a>
                  <a
                    href="https://www.instagram.com/thedutchonlineacademy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Instagram />
                  </a>
                  <a
                    href="https://www.youtube.com/thedutchonlineacademy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Youtube />
                  </a>
                </div>
              </nav>
              <span className="overline">Contact</span>
              <ul>
                {thirdMenu.map((item, index) => (
                  <li key={index}>
                    <Link to={item.address} activeClassName="active">
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
              <p>
                {feedback.title}
                <a href={"mailto:" + feedback.email}>{feedback.email}</a>
              </p>
            </div>
          </div>
        </div>

        <FooterNavigation>
          <div className="content">
            <nav>
              <span>
                The Dutch Online Academy, Omval 1096AA Amsterdam, KVK: 78268419
                VAT: NL003311858B93
              </span>
            </nav>

            <nav>
              <ul>
                <li>
                  <Link to="/en/page/privacy">Privacy policy</Link>
                </li>
                <li>
                  <Link to="/en/page/terms-and-conditions">
                    Terms & conditions
                  </Link>
                </li>
              </ul>
            </nav>

            <span>{copyright}</span>
          </div>
        </FooterNavigation>
      </FooterContainer>
    );
  }
}

const FooterComponent = (props) => (
  <StaticQuery
    query={graphql`
      query FooterPagesQuery {
        enMenu: allContentfulMenu(filter: { node_locale: { eq: "en" } }) {
          edges {
            node {
              location
              title
              items {
                ...InternalLink
              }
            }
          }
        }
        nlMenu: allContentfulMenu(filter: { node_locale: { eq: "en" } }) {
          edges {
            node {
              location
              title
              items {
                ...InternalLink
              }
            }
          }
        }
        enCornerstoneArticles: allContentfulArticle(
          filter: { cornerstone: { eq: true }, node_locale: { eq: "en" } }
        ) {
          edges {
            node {
              cornerstone
              title
              __typename
              node_locale
              slug
            }
          }
        }
        nlCornerstoneArticles: allContentfulArticle(
          filter: { cornerstone: { eq: true }, node_locale: { eq: "nl" } }
        ) {
          edges {
            node {
              cornerstone
              title
              __typename
              node_locale
              slug
            }
          }
        }
        enCornerstonePills: allContentfulPill(
          filter: { cornerstone: { eq: true }, node_locale: { eq: "en" } }
        ) {
          edges {
            node {
              cornerstone
              title
              __typename
              node_locale
              slug
            }
          }
        }
        nlCornerstonePills: allContentfulPill(
          filter: { cornerstone: { eq: true }, node_locale: { eq: "nl" } }
        ) {
          edges {
            node {
              cornerstone
              title
              __typename
              node_locale
              slug
            }
          }
        }
      }
      fragment InternalLink on ContentfulPages {
        slug
        id
        reservedPaths
        __typename
        node_locale
        titleInMenus
      }
    `}
    render={(data) => <Footer {...props} data={data} />}
  />
);
export default FooterComponent;
