import styled from "styled-components";
import MEDIA from "../../theme/helpers/mediaTemplates";
import { theme } from "../../theme/variables";
import mixins from "../../theme/mixins";

export const HeaderWrapper = styled.header`
  top: 0px;
  left: 0px;
  right: 0px;
  position: sticky;
  transition: 1s;
  opacity: 1;
  margin-top: 0px;
  background-color: ${(props) => props.theme.primary};
  z-index: 8;
  .header__menu-hamburger {
    max-width: 30px;
    display: none;
    g {
      fill: ${(props) => props.theme.darkerTwo};
    }
    color: ${(props) => props.theme.darkerTwo};
    ${MEDIA.DESKTOP`
		display: inherit;
    `};
  }
`;
export const HeaderContainer = styled.div`
  padding: 0 6px;
  height: ${theme.headingHeight};
  max-width: ${theme.contentWideMaxWidth};
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  .header__menu-hamburger {
    height: 60px;
    max-width: unset;
    width: 40px;
    flex-shrink: 0;
    margin-right: 6px;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  flex-grow: 1;

  .header__menu-cart {
  }
  .header__menu-hamburger,
  .header__menu-cart {
  }
  .my-account {
    font-weight: 700;
    padding-bottom: 1px;
    color: ${(props) => props.theme.darkerTwo};
    ${MEDIA.PHONE`
      font-size:0.8rem;
    `}
  }
`;

export const Navigation = styled.ul`
  padding: 0px;
  margin: 0px;
  li {
    margin: 0 ${theme.halfGutter} 0 0;
    padding: 5px;
    display: inline-block;
  }
  a {
    color: ${(props) => props.theme.darkerTwo};
    font-weight: 700;
    &.active {
      color: ${(props) => props.theme.lighterTwo};
    }
  }
  .header-tag {
    background: ${(props) => props.theme.contrast_lighterTwo};
    color: ${(props) => props.theme.contrast_darkerTwo};
    font-size: 0.6rem;
    margin-left: 4px;
    position: relative;
    top: -14px;
    padding: 3px 6px;
    letter-spacing: 1px;
    border-radius: 2px;
    box-shadow: 0px 2px 3px ${(props) => props.theme.darkerOne},
      -1px -1px 1px ${(props) => props.theme.lighterOne};
  }
  ${MEDIA.DESKTOP`
		display: none;
    `};

  .snipcart-checkout {
    color: ${theme.colorPrimary};
    border-bottom: none;
  }
`;
export const PillContainer = styled.div`
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.darkerTwo};

  border-radius: 30px;
  padding: 4px;
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.halfUp},
    ${(props) => props.theme.primary}
  );
  box-shadow: 5px 5px 7px ${(props) => props.theme.darkerOne},
    -2px -2px 4px ${(props) => props.theme.lighterOne};

  .headerIcon {
    width: 52px;
    height: auto;
    g {
      fill: ${(props) => props.theme.darkerTwo};
    }
    color: ${(props) => props.theme.darkerTwo};
    padding: 8px 15px;
    &#languageSwitcher {
      padding: 0px 14px;
    }
  }
  .header_dot {
    height: 15px;
    width: 15px;
    background-color: ${(props) => props.theme.contrast_primary};
    box-shadow: 5px 5px 7px ${(props) => props.theme.darkerOne},
      -2px -2px 4px ${(props) => props.theme.lighterOne};
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: 9px;
    top: 0px;
  }
  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    ${MEDIA.PHONE`
		padding: 0px;
		`}
  }
  li {
    margin: 0px;
    padding: 0px;
    line-height: 1;
    display: inline-block;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      z-index: 7;
      left: -2px;
      top: 50%;
      transform: translateY(-50%);
      height: 12px;
      width: 6px;
      background: ${(props) => props.theme.primary};
      border-radius: 4px;
      box-shadow: inset 1px 1px 2px ${(props) => props.theme.darkerOne},
        inset -1px -1px 2px ${(props) => props.theme.lighterTwo};
    }
    &:first-child {
      &::after {
        background: none;
        box-shadow: none;
      }
    }
  }
  ${MEDIA.MIN_DESKTOP`
		.mobile_navigation{
			display: none;	
		}
	`}
  ${MEDIA.MINIPHONE`
		.headerIcon{
			width: 38px;
			padding: 10px;
		}
		.header_dot{
			left: 3px;
		}
	`}
	height: fit-content;
`;

export const LanguageSwitcherContainer = styled.div`
  color: ${theme.colorSecondaryDark};
  font-weight: 700;
  line-height: 1;
  width: auto;
  border-left: 2px solid ${(props) => props.theme.darkerOne};
  padding-left: 8px;
  margin-left: 8px;
  margin-top: 2px;
  a {
    font-size: 14px;
    display: block;
  }

  svg {
    max-width: 28px;
    max-height: 20px;
    border: 2px solid ${(props) => props.theme.darkerOne};
    border-radius: 4px;
    box-sizing: border-box;
    margin-left: 6px;
  }
  .currentLang_en.langTag_en {
    display: none;
  }
  .currentLang_nl.langTag_nl {
    display: none;
  }
  ${MEDIA.MINIPHONE`
		svg {
			max-width: 22px;
			max-height: 16px;
			border: 1px solid ${(props) => props.theme.lighterTwo};

		}
	`}
`;

export const CartContainer = styled.div`
  position: relative;
  min-width: 40px;
  min-height: 40px;
  border-radius: 40px;
  margin: 0 0 0 10px;
  line-height: 40px;
  text-align: center;
  flex: 0 0;
  width: 40px;
  transition: 0.3s;

  &.active {
    display: flex;
    min-width: unset;

    width: auto;
    min-width: 118px;
    justify-content: center;
    svg {
      flex-shrink: 0;
      width: 40px;
    }
    ${mixins.shadowed.transparent.large};

    background: ${(props) => props.theme.contrast_primary};
    & svg,
    & g {
      fill: white !important;
      color: white !important;
      filter: drop-shadow(0px 1px 2px black);
    }
  }
  .header__cart-message {
    font-size: 0.8rem;
    padding-left: 10px;
    color: white;
    text-shadow: 0 1px 3px black;
    font-weight: 700;
  }
  .header__menu-cart {
    max-width: 40px;
    max-height: 40px;
    margin: 0;
    padding: 10px;

    g {
      fill: ${(props) => props.theme.darkerTwo};
    }
    color: ${(props) => props.theme.darkerTwo};

    /* ${MEDIA.DESKTOP`
      max-width: 30px;
    `}; */
  }
  .count {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    font-weight: 500;
    color: ${(props) => props.theme.darkerTwo};
    background: ${(props) => props.theme.lighterTwo};
    ${mixins.shadowed.primary.small};

    width: 16px;
    height: 16px;
    display: block;
    border-radius: 20px;
    text-align: center;
    line-height: 16px;
  }
`;
