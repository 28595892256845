import styled from "styled-components";
import mixins from "../../theme/mixins";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 32px 0 8px 0;
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    width: 25px;
    height: 25px;
  }
  a {
    display: block;
    padding: 10px 22px 8px;
    fill: white;
    border: 1px solid ${(props) => props.theme.halfUp};
    margin-left: 12px;
    border-radius: 100px;
    ${mixins.colored.primary}
    ${mixins.shadowed.primary.small}
  }
`;

const allStyled = {
  Wrapper,
  Icons,
};

export default allStyled;
