import React, { useEffect, useReducer } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import commerceReducer from "./reducers/commerceReducer";
import userReducer from "./reducers/userReducer";
import appConnection from "./appConnection";
import dataModel from "./dataModel";
import combineReducers from "../utils/combineReducers";
export const AppContext = React.createContext();

const AppWrapper = (props) => {
  const [state, dispatch] = useReducer(
    combineReducers({ cart: commerceReducer, user: userReducer }),
    dataModel,
  );

  const {
    isAuthenticated,
    user,
    logout,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch({ type: "authenticated-user", payload: user });
      const fetchData = async () => {
        const token = await getAccessTokenSilently();
        const apiReponse = await appConnection(token);
        if (apiReponse && apiReponse.user) {
          dispatch({ type: "user-data-received", payload: apiReponse.user });
        }
      };
      fetchData();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!window || state.cart.count) return;
    const recoveredCart = localStorage.getItem("cart");
    if (recoveredCart) {
      dispatch({
        type: "recover-cart",
        payload: {
          recoveredCart: JSON.parse(recoveredCart),
        },
      });
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        state,
        getAccessTokenSilently,
        dispatch,
        logout,
        loginWithRedirect,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = React.useContext(AppContext);
  // if (context === undefined) {
  //   // throw new Error("useCount must be used within a CountProvider");
  //   // eslint-disable-next-line no-console
  //   console.log("oh my god, context is undefined");
  // }
  return context || dataModel;
};

export { useAppContext };

export default AppWrapper;
