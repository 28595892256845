import styled from "styled-components";
import { theme } from "../../theme/variables";
import MEDIA from "../../theme/helpers/mediaTemplates";

export const Hero = styled.div`
  padding: 0px 32px 48px 32px;
  background-color: ${(props) => props.theme.primary};
  .content {
    max-width: ${theme.contentWideMaxWidth};
    margin: 0 auto;
    padding: 80px 0 0 0;
    color: white;
    .gatsby-image-wrapper {
      margin: 0 190px;
    }
    ${MEDIA.TABLET`
			padding-top: 52px;
			.gatsby-image-wrapper{
				margin: 0 -32px;
			}
		`}
    h1 {
      margin: 52px 0;
      text-align: center;
      /* white-space: pre; */
      font-size: 1.5rem;
      ${MEDIA.MIN_DESKTOP`
				font-size: 2rem;
				white-space: pre;
			`}
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  p {
    color: inherit;
  }
`;
export const SubscribeCard = styled.div`
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.default_lighterTwo},
    ${(props) => props.theme.default_lighterOne}
  );
  box-shadow: 5px 5px 7px ${(props) => props.theme.darkerOne},
    -2px -2px 4px ${(props) => props.theme.lighterOne};
  max-width: ${theme.contentNarrowMaxWidth};
  margin: 0 auto;
  padding: 120px 64px;
  border-radius: 32px;
  color: ${(props) => props.theme.darkerTwo};
  h2 {
    text-align: center;
    font-size: 2rem;
  }
  h4 {
    /* text-align: center; */
    font-size: 1.4rem;
    line-height: 1.4;
  }
  /* text-align: center; */
  ${MEDIA.PHONE`
		h2 {
			font-size: 1.4rem;
		}
		h4 {
			font-size: 1rem;
		}
		padding: 64px 16px;
	`}
`;

// export const IndexLinks = styled.div`
// 	.content {
// 		margin: auto;
// 		max-width: ${theme.contentNarrowMaxWidth};
// 		padding: 200px 32px;
// 		text-align: center;
// 		h3 {
// 			text-align: center;
// 			margin-bottom: 64px;
// 			text-decoration: underline;
// 		}
// 	}
// `

export const ServiceList = styled.div`
	padding: 64px 32px;
	.content {
		margin: auto;
		max-width: ${theme.contentWideMaxWidth};
		background: linear-gradient(
			135deg,
			${(props) => props.theme.default_lighterTwo},
			${(props) => props.theme.default_lighterOne}
		);
		box-shadow: 5px 5px 7px ${(props) => props.theme.default_darkerOne},
			-2px -2px 4px ${(props) => props.theme.default_lighterOne};
		padding: 40px;
		border-radius: 20px;
		margin-bottom: 30px;
		padding: 80px 0px;
		border: 2px solid white;

		h2 {
			text-align: center;
		}
		.services {
			display: flex;
			justify-content: space-around;
			flex-direction: row;
			padding: 60px 30px;
			flex-wrap: wrap;
		}

		.item {
			/* display: inline-block; */
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			.icon {
				border-radius: 200px;
				width: 150px;
				height: 150px;
				/* padding: 55px; */
				background: linear-gradient(
					135deg,
					${(props) => props.theme.default_primary},
					${(props) => props.theme.default_lighterOne}
				);
				box-shadow: 5px 5px 7px
						${(props) => props.theme.default_darkerOne},
					-2px -2px 4px ${(props) => props.theme.default_lighterTwo};
				border: 2px solid white;
				position: relative;

				&:hover{
					background: linear-gradient(
						135deg,
						${(props) => props.theme.default_lighterOne},
						${(props) => props.theme.default_primary}
					);
					box-shadow: 10px 10px 14px
						${(props) => props.theme.default_darkerOne},
					-4px -4px 7px ${(props) => props.theme.default_lighterTwo};
				}
			}
			svg {
				position: absolute;
				width: 70px;
				height: auto;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
			span {
				margin-top: 20px;
				font-weight: 700;
			}
			
			${MEDIA.TABLET`
				.icon{
					width: 120px;
					height: 120px;
					margin: 10px;
					svg{
						width: 60px;
					}
				}
				span{
					max-width: 120px;
					display: block;
				}
			`}
			${MEDIA.PHONE`
				.icon{
					width: 100px;
					height: 100px
				}
			`}
	}
`;
