import React, { useEffect } from "react";
import pathBuilder from "../../utils/pathBuilder";
import {
  Column,
  Container,
  ContainerWide,
  Row,
} from "../../components/System/Grids";
import PageIntro from "../../components/PageIntro/PageIntro";
import BookGridItem from "../../components/BookGridItem/BookGridItem";
import Layout from "../../components/Layout/Layout";
import shopInfo from "../../../data/shopInfo";
import { pushImpressionView } from "../../utils/tagManager";
const ShopTemplate = (props) => {
  const { books } = props.data;
  const { environment } = props;
  const content = shopInfo[environment.lang];

  const seo = {};
  if (content.seo.description) {
    seo.description = content.seo.description;
  }
  if (content.seo.title) {
    seo.title = content.seo.title;
  } else {
    seo.title = content.hero.title;
  }
  environment.seo = seo;
  useEffect(() => {
    pushImpressionView({
      productList: books.edges.map((book) => ({
        id: book.node.productId,
        name: book.node.title,
        category: `books`,
        price: book.price,
      })),
      location: "shop_lister",
    });
  }, []);

  return (
    <Layout environment={environment}>
      {content ? (
        <PageIntro>
          {content.hero.title ? <h1>{content.hero.title}</h1> : null}
          {content.hero.subtitle ? <h2>{content.hero.subtitle}</h2> : null}
        </PageIntro>
      ) : null}

      <ContainerWide>
        <Row>
          {books.edges.map(({ node }, index) => {
            return (
              <BookGridItem
                key={index}
                title={node.title}
                price={node.price}
                subtitle={node.subtitle}
                level={node.level}
                productId={node.productId}
                studentLevel={node.studentLevel}
                numberOfPages={node.numberOfPages}
                fileIdSnipcart={node.fileIdSnipcart}
                image={node.image}
                contentful_id={node.contentful_id}
                slug={pathBuilder(node.node_locale, node.__typename, node.slug)}
                lang={environment.lang}
                urlSnipcart={environment.lang === "en" ? "/en/shop" : "/shop"}
                location={"shop_lister"}
              />
            );
          })}
        </Row>
      </ContainerWide>
      <Container>
        <Row>
          <Column>
            <div className="text-body" style={{ whiteSpace: "pre-wrap" }}>
              {content.body.bottom}
            </div>
          </Column>
        </Row>
      </Container>
    </Layout>
  );
};
export default ShopTemplate;
