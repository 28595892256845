import styled from "styled-components";

export const Wrapper = styled.div`
  background: white;
  padding: 0px;
  border-radius: 32px;
  overflow: hidden;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 5px 5px 7px ${(props) => props.theme.default_primary},
    -2px -2px 4px white;
  max-width: 400px;
  border: 1px solid white;
  .title {
    margin: 10px 0 16px;
  }
  .description {
    line-height: 1;
    letter-spacing: 0px;
  }
`;

export const TileImage = styled.div`
  min-height: 300px;
`;
export const Container = styled.div`
  padding: 16px 32px 32px;
`;

export const Overline = styled.span`
  background: ${(props) => props.theme.primary};
  display: inline-block;
  width: auto;
  margin-right: auto;
  padding: 5px 12px;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    flex-grow: 1;
  }
`;
