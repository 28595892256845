const pushEvent = ({ event, location, action, label }) => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: event,
      data: {
        location,
        action,
        label,
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ e });
  }
};

// category as in books/grammar/B1
// books/stories/A2

const pushImpressionView = ({ productList, location }) => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "eec.impressionView",
      ecommerce: {
        impressions: productList.map((product, index) => ({
          id: product.id,
          name: product.name,
          category: product.category,
          list: location,
          price: product.price,
          position: index,
        })),
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log({ e });
  }
};
const pushImpressionClick = ({ productList, location }) => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "eec.impressionClick",
      ecommerce: {
        click: {
          actionField: {
            list: location,
          },
          products: productList.map((product, index) => ({
            id: product.id,
            name: product.name,
            category: product.category,
            list: location,
            price: product.price,
            position: index,
          })),
        },
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ e });
  }
};

const pushDetailView = ({ id, name, category, price, location }) => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "eec.detail",
      ecommerce: {
        detail: {
          actionField: {
            list: location,
          },
          products: [
            {
              id,
              name,
              category,
              type: "Ecommerce",
              price,
            },
          ],
        },
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ e });
  }
};

// logs the new products in the cart (not the total!!)
const pushAddToCart = ({ productList }) => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "eec.add",
      ecommerce: {
        add: {
          // actionField: {
          //   list: "Shopping cart",
          // },
          products: productList.map((product, index) => ({
            id: product.id,
            name: product.name,
            category: product.category,
            quantity: product.quantity,
            price: product.price,
          })),
        },
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ e });
  }
};

const pushRemoveFromCart = ({ productList }) => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "eec.remove",
      ecommerce: {
        remove: {
          actionField: {
            list: "Shopping cart",
          },
          products: productList.map((product, index) => ({
            id: product.id,
            name: product.name,
            category: product.category,
            quantity: product.quantity,
            price: product.price,
          })),
        },
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ e });
  }
};

const pushGoToCheckout = ({ step, productList, action }) => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "eec.checkout",
      ecommerce: {
        checkout: {
          actionField: {
            step,
            action,
          },
          products: productList.map((product, index) => ({
            id: product.id,
            name: product.name,
            category: product.category,
            price: product.price,
            quantity: product.quantity,
          })),
        },
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ e });
  }
};

// price as in '11.00'
const pushPurchase = ({ orderId, orderRevenue, productList }) => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "eec.purchase",
      ecommerce: {
        currencyCode: "EUR",
        purchase: {
          actionField: {
            id: orderId,
            // affiliation: "Simo's shop",
            revenue: orderRevenue,
            // tax: "1.00",
            // shipping: "2.00",
            // coupon: "SUMMER2019",
          },
          products: productList.map((product, index) => ({
            id: product.id,
            name: product.name,
            category: product.category,
            quantity: product.quantity,
            price: product.price,
          })),
        },
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ e });
  }
};

export {
  pushImpressionView,
  pushImpressionClick,
  pushDetailView,
  pushAddToCart,
  pushRemoveFromCart,
  pushGoToCheckout,
  pushPurchase,
  pushEvent,
};
