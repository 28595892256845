import { theme } from "../../theme/variables";
import styled from "styled-components";
import MEDIA from "../../theme/helpers/mediaTemplates";

const BookSectionWrapper = styled.div`
  background: ${(props) => props.theme.lighterTwo};
  width: 100%;
  .header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Content = styled.div`
  max-width: ${theme.contentWideMaxWidth};
  margin: auto;
  margin-top: -150px;
  /* position: relative; */
  display: flex;
  flex-direction: column;
`;

export const ConceptIntroContainer = styled.div`
  max-width: ${theme.contentMaxWidth};
  margin: auto;
  margin-top: -2px;
  padding: 52px 32px 20px;

  ${MEDIA.PHONE`
		padding: 68px 16px 16px;
	`}
  margin-bottom: 2rem;
`;

export const ConceptIntro = styled.div`
  background-color: ${(props) => props.theme.primary};
  box-shadow: 3px 3px 6px ${(props) => props.theme.default_BlackTransparent};
  padding-bottom: 100px;
  h1,
  h4 {
    color: white;
    margin-bottom: 1em;
    font-weight: 700;
  }
  p {
    color: white;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    a {
      color: white;
      text-decoration: underline;
      font-weight: 700;
    }
    &:last-child {
      margin-bottom: 3rem;
    }
  }
  ${MEDIA.PHONE`
		p{
			margin-bottom: 0.5rem;
			&:last-child{
				margin-bottom: 1rem;
			}
		}
		h1{

			font-size: 1.8rem;
		}
	`}
`;

export const SecondaryNavigationContainer = styled.div`
  z-index: 2;
  background-color: ${(props) => props.theme.primary};
  box-shadow: 3px 3px 3px ${(props) => props.theme.default_BlackTransparent},
    -2px -2px 2px ${(props) => props.theme.lighterOne};
  padding: 0px 12px;
  margin: 0px auto auto 0px;
  max-width: 100%;
  border-radius: 50px;
  position: static;
  padding-bottom: 8px;
  z-index: 3;
  margin-bottom: 1rem;
  &.fixed {
    position: fixed;
    margin: 0px;
    left: 0px;
    right: 0px;
    border-radius: 0px;
    border-top: 2px solid ${(props) => props.theme.darkerOne};
    ul {
      margin-left: auto;
      margin-right: auto;
    }
    li {
      a {
        line-height: 1.1rem;
      }
    }
  }
  ${MEDIA.MIN_TABLET`
		display: inline-block;
	`}
  ul {
    max-width: ${theme.contentNarrowMaxWidth};
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    ::-webkit-scrollbar {
      position: absolute;
      bottom: 10px;
      height: 2px;
    }
    ::-webkit-scrollbar-track {
      background-color: ${(props) => props.theme.primary};
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${(props) => props.theme.halfUp};
    }
    li {
      list-style: none;
      display: inline-block;
      margin: 0px;
      padding: 0px;
      position: relative;
      span {
        display: block;
        line-height: 0.7rem;
      }

      a {
        padding: 6px 8px 0px 8px;
        font-size: 0.7rem;
        width: 100%;
        display: block;
        font-weight: 700;
        color: white;
        cursor: pointer;
        /* border-right: 2px solid ${(props) => props.theme.darkerOne}; */
        &.active {
          text-decoration: underline;
        }
        ${MEDIA.MIN_TABLET`
					font-size: 1rem;
				`}
      }
    }
    .separator {
      position: relative;
      display: block;
      top: 3px;
      min-width: 4px;
      min-height: 4px;
      border-radius: 10px;
      background-color: ${(props) => props.theme.lighterOne};
      &:last-child {
        display: none;
      }
    }
  }
`;

export const SectionContainer = styled.div`
  padding: 32px 42px;
  border-radius: 32px;
  margin-bottom: 32px;
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.default_lighterTwo},
    ${(props) => props.theme.default_lighterOne}
  );
  box-shadow: 5px 5px 7px #00000044,
    -2px -2px 2px ${(props) => props.theme.default_lighterOne};
  .header {
    box-shadow: inset 1px 2px 4px ${(props) => props.theme.default_halfUp},
      inset -1px -2px 4px white;
    border: 1px solid ${(props) => props.theme.default_lighterTwo};
    color: ${(props) => props.theme.default_darkerOne};

    display: inline-block;
    text-align: right;
    margin: 0px 0px 10px auto;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 0.9rem;
  }
  h3 {
    font-size: 2rem;
  }
  p {
    a {
      font-weight: 700;
      color: ${(props) => props.theme.darkerTwo};
      border-bottom: 2px solid ${(props) => props.theme.darkerTwo};
    }
  }
  ${MEDIA.PHONE`
		padding:16px;
		h3{
			margin-top: 1.5em;
			font-size: 1.5rem;
			margin-bottom: 0;
		}
	`}
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: ${theme.halfGutter};

  ${MEDIA.PHONE`
	    padding: 18px;
    `}
  ${MEDIA.MIN_DESKTOP`
	    padding: ${theme.gridGutter} 150px;
    `}
`;

const allStyled = {
  Content,
  ConceptIntroContainer,
  ConceptIntro,
  SecondaryNavigationContainer,
  SectionContainer,
  BodyContainer,
  BookSectionWrapper,
};

export default allStyled;
