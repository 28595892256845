import React, { useEffect, useState } from "react";
import * as Styled from "./styled";
import Button from "../UI/Button";
import { getCookie, setCookie } from "../../utils/cookies";

const CookieConsent = () => {
  const [cookieClasses, set_cookieClasses] = useState("");

  useEffect(() => {
    window.setTimeout(function () {
      const hasCookie = getCookie("tdoa-cookie-accepted");
      if (!hasCookie) set_cookieClasses("visible");
    }, 800);
  }, []);

  if (cookieClasses === "visible")
    return (
      <Styled.Wrapper id="cookie-consent" className={cookieClasses}>
        <Styled.Container>
          <p>
            We use cookies and similar techniques to provide you with the best
            user experience. In addition to functional cookies, which make the
            website work properly, we also place analytical cookies to improve
            our website. <a href="/en/page/privacy">more info</a> about cookies.
          </p>
          <Styled.ActionsWrapper>
            <Button.ContrastDefault
              className="cookie-consent__accept"
              onClick={() => {
                setCookie("tdoa-cookie-accepted", "true", 9999);
                set_cookieClasses("");
              }}
            >
              Accept
            </Button.ContrastDefault>
          </Styled.ActionsWrapper>
        </Styled.Container>
      </Styled.Wrapper>
    );
  return null;
};

export default CookieConsent;
