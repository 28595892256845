const allThemes = {
  green: {
    lighterTwo: "hsl(85, 70%, 90%)",
    lighterOne: "hsl(85, 70%, 70%)",
    halfUp: "hsl(85, 70%, 50%)",
    primary: "hsl(85, 70%, 40%)",
    darkerOne: "hsl(85, 70%, 30%)",
    darkerTwo: "hsl(85, 70%, 15%)",
    default_lighterTwo: "hsl(0, 0%, 95%)",
    default_lighterOne: "hsl(0, 0%, 90%)",
    default_halfUp: "hsl(0, 0%, 80%)",
    default_primary: "hsl(0, 0%, 70%)",
    default_darkerOne: "hsl(0, 0%, 45%)",
    default_darkerTwo: "hsl(0, 0%, 15%)",
    default_BlackTransparent: "hsla(0, 0%, 0%, 0.5)",
    contrast_lighterTwo: "hsl(18, 90%, 80%)",
    contrast_lighterOne: "hsl(18, 90%, 60%)",
    contrast_halfUp: "hsl(18, 90%, 55%)",
    contrast_primary: "hsl(18, 90%, 50%)",
    contrast_darkerOne: "hsl(18, 90%, 25%)",
    contrast_darkerTwo: "hsl(18, 90%, 20%)",
  },
  orange: {
    lighterTwo: "hsl(18, 90%, 80%)",
    lighterOne: "hsl(18, 90%, 60%)",
    halfUp: "hsl(18, 90%, 55%)",
    primary: "hsl(18, 90%, 50%)",
    darkerOne: "hsl(18, 90%, 35%)",
    darkerTwo: "hsl(18, 90%, 20%)",
    contrast_lighterTwo: "hsl(200, 90%, 90%)",
    contrast_lighterOne: "hsl(200, 90%, 70%)",
    contrast_halfUp: "hsl(200, 90%, 55%)",
    contrast_primary: "hsl(200, 90%, 50%)",
    contrast_darkerOne: "hsl(200, 90%, 35%)",
    contrast_darkerTwo: "hsl(200, 90%, 20%)",
    default_lighterTwo: "hsl(0, 0%, 95%)",
    default_lighterOne: "hsl(0, 0%, 90%)",
    default_halfUp: "hsl(0, 0%, 80%)",
    default_primary: "hsl(0, 0%, 70%)",
    default_darkerOne: "hsl(0, 0%, 45%)",
    default_darkerTwo: "hsl(0, 0%, 15%)",
    default_BlackTransparent: "hsla(0, 0%, 0%, 0.3)",
  },
  blue: {
    lighterTwo: "hsl(200, 90%, 90%)",
    lighterOne: "hsl(200, 90%, 70%)",
    halfUp: "hsl(200, 90%, 55%)",
    primary: "hsl(200, 90%, 50%)",
    darkerOne: "hsl(200, 90%, 35%)",
    darkerTwo: "hsl(200, 90%, 20%)",
    default_lighterTwo: "hsl(0, 0%, 95%)",
    default_lighterOne: "hsl(0, 0%, 90%)",
    default_halfUp: "hsl(0, 0%, 80%)",
    default_primary: "hsl(0, 0%, 70%)",
    default_darkerOne: "hsl(0, 0%, 45%)",
    default_darkerTwo: "hsl(0, 0%, 15%)",
    default_BlackTransparent: "hsla(0, 0%, 0%, 0.5)",

    contrast_lighterTwo: "hsl(18, 90%, 80%)",
    contrast_lighterOne: "hsl(18, 90%, 60%)",
    contrast_halfUp: "hsl(18, 90%, 55%)",
    contrast_primary: "hsl(18, 90%, 50%)",
    contrast_darkerOne: "hsl(18, 90%, 25%)",
    contrast_darkerTwo: "hsl(18, 90%, 20%)",
  },
  purple: {
    lighterTwo: "hsl(255, 50%, 95%)",
    lighterOne: "hsl(255, 50%, 80%)",
    halfUp: "hsl(255, 50%, 70%)",
    primary: "hsl(255, 50%, 60%)",
    darkerOne: "hsl(255, 50%, 25%)",
    darkerTwo: "hsl(255, 50%, 5%)",
    default_lighterTwo: "hsl(0, 0%, 95%)",
    default_lighterOne: "hsl(0, 0%, 90%)",
    default_halfUp: "hsl(0, 0%, 80%)",
    default_primary: "hsl(0, 0%, 70%)",
    default_darkerOne: "hsl(0, 0%, 45%)",
    default_darkerTwo: "hsl(0, 0%, 15%)",
    default_BlackTransparent: "hsla(0, 0%, 0%, 0.5)",

    contrast_lighterTwo: "hsl(18, 90%, 80%)",
    contrast_lighterOne: "hsl(18, 90%, 60%)",
    contrast_halfUp: "hsl(18, 90%, 55%)",
    contrast_primary: "hsl(18, 90%, 50%)",
    contrast_darkerOne: "hsl(18, 90%, 25%)",
    contrast_darkerTwo: "hsl(18, 90%, 20%)",
  },
  pink: {
    lighterTwo: "hsl(348, 75%, 90%)",
    lighterOne: "hsl(348, 75%, 75%)",
    halfUp: "hsl(348, 75%, 64%)",
    primary: "hsl(348, 75%, 57%)",
    darkerOne: "hsl(348, 75%, 30%)",
    darkerTwo: "hsl(348, 75%, 20%)",
    default_lighterTwo: "hsl(0, 0%, 95%)",
    default_lighterOne: "hsl(0, 0%, 90%)",
    default_halfUp: "hsl(0, 0%, 80%)",
    default_primary: "hsl(0, 0%, 70%)",
    default_darkerOne: "hsl(0, 0%, 45%)",
    default_darkerTwo: "hsl(0, 0%, 15%)",
    default_BlackTransparent: "hsla(0, 0%, 0%, 0.5)",
    contrast_lighterTwo: "hsl(200, 90%, 90%)",
    contrast_lighterOne: "hsl(200, 90%, 70%)",
    contrast_halfUp: "hsl(200, 90%, 55%)",
    contrast_primary: "hsl(200, 90%, 50%)",
    contrast_darkerOne: "hsl(200, 90%, 35%)",
    contrast_darkerTwo: "hsl(200, 90%, 20%)",
  },
};

export default allThemes;
