import React from "react";
import { useAppContext } from "../../context/appProvider";
import { ShoppingBag } from "styled-icons/boxicons-regular";
import { Link } from "gatsby";
import { CartContainer } from "./Header.styled";

const Cart = (props) => {
  // const { count } = props;
  const ctx = useAppContext();
  const { state } = ctx;

  if (!state || !state.cart) return null;

  return (
    <CartContainer
      as={Link}
      to={"/en/cart"}
      className={state.cart.items && state.cart.items.length ? "active" : null}
    >
      {state.cart.items && state.cart.items.length ? (
        <span className="header__cart-message">Cart</span>
      ) : null}
      <ShoppingBag className={"header__menu-cart"} />
      {state.cart.items && state.cart.items.length ? (
        <span className={"count"}>{state.cart.items.length}</span>
      ) : null}
    </CartContainer>
  );
};

export default Cart;
