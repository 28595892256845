import React from "react";
import moment from "moment";
import { Link } from "gatsby";
import pathBuilder from "../../../utils/pathBuilder";
import Styled from "../styled";

export default function UpcomingCourses(props) {
  const { courses } = props;

  const getUpcomingTwoCourses = () => {
    const allGroups =
      courses.edges &&
      courses.edges
        .map((edge) => {
          return edge.node.groups.map((group) => {
            const groupsObject = {
              starting: group.starting,
              course: edge.node.title,
              slug: edge.node.slug,
            };
            return groupsObject;
          });
        })
        .flat()
        .filter((g) => {
          if (moment(g.starting) > moment().add(-1, "days")) return g;
          return null;
        });

    return allGroups
      .sort((a, b) => {
        if (moment(a.starting) > moment(b.starting)) return 1;
        if (a.starting === b.starting) return 0;
        return -1;
      })
      .slice(0, 2);
  };

  const giveTimeDifference = (dateA) => {
    return `Starts on ${moment(dateA).format("dddd Do")}`;
  };

  return (
    <Styled.Header>
      <h2>Upcoming courses:</h2>
      <div>
        {getUpcomingTwoCourses().map((courseObj) => (
          <Styled.UpcomingCourseGridItem
            as={Link}
            to={pathBuilder(
              "en",
              "ContentfulLiveOnlineCourses",
              courseObj.slug
            )}
            key={courseObj.slug}
          >
            <span className="overline">
              {giveTimeDifference(courseObj.starting)}
            </span>
            <h4>{courseObj.course}</h4>
            <u>View course</u>
          </Styled.UpcomingCourseGridItem>
        ))}
      </div>
    </Styled.Header>
  );
}
