import styled from "styled-components";
import MEDIA from "../../theme/helpers/mediaTemplates";
import { theme } from "../../theme/variables";

export const FooterContainer = styled.footer`
  width: 100%;
  .placeholder {
    position: relative;
    background-color: ${(props) => props.theme.default_lighterTwo};
    /* Removing the line on top (shadow) */
    border-top: 14px solid ${(props) => props.theme.default_lighterTwo};
    box-shadow: 7px 7px 7px ${(props) => props.theme.darkerTwo};
    padding: 32px;
    text-align: center;
    z-index: 1;
    padding-bottom: 120px;
    margin-bottom: -153px;
    border-radius: 0 0 240px 240px;
    font-size: 1rem;
    a {
      border-bottom: 2px solid ${(props) => props.theme.primary};
      font-weight: 700;
    }
    ${MEDIA.PHONE`
            padding-bottom: 40px;
            border-radius: 0 0 80px 80px; 
            margin-bottom: -80px;
        `}
  }

  #footer-cta {
    color: white;
    background-color: ${(props) => props.theme.primary};
    padding-top: 180px;
    ${MEDIA.PHONE`
            padding-top: 80px;
            margin-top: -80px;
        `}
    position: relative;
    z-index: 0;
    min-height: 60vh;
    margin: auto;
    box-shadow: 7px 7px 7px ${(props) => props.theme.darkerTwo};

    nav {
      ul {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 0px;
        margin: 0px;
      }
      li {
        list-style: none;
        display: inline-block;
        font-size: 0.8rem;
        font-weight: 700;
        line-height: 1;
        padding: 10px 0;
        margin-right: 16px;
      }
      a {
        color: white;
        line-height: 0.8;
      }
    }
    .overline {
      color: white;
      font-weight: 800;
      font-size: 1.2rem;
      display: inline-block;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      padding-top: 1.4rem;
      border-bottom: 2px solid ${(props) => props.theme.lighterTwo};
    }
    .content {
      display: flex;
      flex-direction: row;
      align-items: top;
      padding: 64px ${theme.gridGutter};

      justify-content: space-between;
      max-width: ${theme.contentWideMaxWidth};
      margin: auto;
      .column {
        max-width: 33%;
        p {
          a {
            color: white;
            border-bottom: none;
            display: inline;
            text-decoration: underline;
          }
        }
      }
    }

    ${MEDIA.TABLET`
        .content{
            display: flex;
			flex-direction: column;
			
			.column{
				max-width: 100%;
			}

        }
            
            
            nav{
                ul{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                }
            }

        `}

    h3,
		p {
      color: white;
    }
    position: relative;
    border-radius: 0 0 240px 240px;
    margin-bottom: -240px;
    padding-left: ${theme.gridGutter};
    padding-right: ${theme.gridGutter};
    ${MEDIA.PHONE`
            padding-bottom: 80px;
            margin-bottom: -80px;
            border-radius: 0 0 80px 80px; 
        `}
    .content {
      margin: auto;
      padding-top: ${theme.gridGutter};
      max-width: ${theme.contentWideMaxWidth};
    }
    h3 {
      font-size: 1.2rem;
    }
    p {
      font-size: 0.9rem;
    }
  }

  .buttons {
    text-align: center;
    display: flex;
    flex-direction: row;

    a {
      span {
        color: ${(props) => props.theme.default_darkerTwo};
      }
      display: flex;
      width: 40px;
      height: 40px;
      border-radius: 34px;
      flex-direction: row;
      justify-content: space-around;
      vertical-align: middle;
      background: linear-gradient(
        135deg,
        ${(props) => props.theme.default_lighterTwo},
        ${(props) => props.theme.default_lighterOne}
      );
      font-weight: 700;
      box-shadow: 5px 5px 7px ${(props) => props.theme.darkerOne},
        -1px -1px 2px ${(props) => props.theme.default_lighterTwo};
      text-align: left;
      align-items: center;
      margin: 16px 5px;
      ${MEDIA.MIN_TABLET`
				margin-right:32px;
				&:last-child {
					margin-right:0px;
				}
			`}
    }
    svg {
      width: 19px;
      height: auto;
      fill: ${(props) => props.theme.darkerOne};
    }
  }
`;

export const FooterNavigation = styled.div`
  width: 100%;
  padding-top: 240px;
  background-color: ${(props) => props.theme.default_lighterTwo};
  ${MEDIA.PHONE`
        padding-top: 80px;
    `}
  h3,p, a, span {
    color: ${(props) => props.theme.default_darkerTwo};
    font-size: 0.8rem;
    line-height: 0.8rem;
    padding: 0px;
    margin: 0px;
  }
  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px ${theme.gridGutter};
    justify-content: space-between;
    max-width: ${theme.contentWideMaxWidth};
    margin: auto;
    padding: ${theme.gridGutter};
    padding-bottom: 100px;
    gap: 32px;
  }

  nav {
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0px;
      margin: 0px;
    }
    li {
      list-style: none;
      display: inline-block;
      font-weight: 700;
      margin-right: 16px;
    }
  }

  ${MEDIA.PHONE`
        .content{
            display:flex;
			flex-direction:column;
		padding-bottom:120px;


        }
		nav{
			ul{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
			}
		}

	`}
`;
