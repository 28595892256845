import styled from "styled-components";
import { theme } from "../../theme/variables";
import MEDIA from "../../theme/helpers/mediaTemplates";

export const Content = styled.div`
  p,
  a,
  h1,
  h2,
  h3,
  h4,
  span,
  div,
  img {
    font-family: ${theme.fontHeaders};
  }
  div,
  img {
    max-width: 100%;
  }

  form.mailchimp_newsletter_form {
    border-radius: 10px;
    margin: auto;
    padding: 0px;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: left;
    justify-content: space-between;
    box-sizing: border-box;
    text-align: inherit;
    .form-section {
      margin-top: 12px;
      h3 {
        margin: 0;
      }
    }
    .radio-container {
      display: flex;
      justify-content: space-between;
      label {
        min-width: 17%;
        display: block;
      }
    }
    .radio-button {
      display: block;
      /* width: 40px; */
      min-width: 10%;
      height: 40px;
      flex-shrink: 1;
      flex-grow: 1;
      border-radius: 20px;
      background: ${(props) => props.theme.default_lighterTwo};
      border: 3px solid ${(props) => props.theme.lighterOne};
      box-sizing: content-box;
      font-size: 20px;
      padding: 24px 10px 10px;
      text-align: center;
      margin-top: 12px;
      /* margin-left:12px; */
    }
    input:checked + .radio-button {
      background: ${(props) => props.theme.primary};
    }
    input {
      display: block;
      font-size: 17px;

      border: none;
      background: none;
      border-radius: 60px;
      padding: 10px 10px;
      margin: 0px;
      margin-top: 20px;
      width: auto;
      height: auto;
      color: ${(props) => props.theme.darkerTwo};
      font-weight: 400;
      box-shadow: inset 4px 4px 7px ${(props) => props.theme.default_primary},
        inset -5px -5px 7px ${(props) => props.theme.default_lighterOne};
      background: white;
      text-align: center;

      &.hidden {
        display: none;
      }

      ${MEDIA.MIN_PHONE`
				// font-size: 24px;
				width: 100%;
				padding: 16px 8px;
				margin: 5px 0;

			`}
      ${MEDIA.PHONE`
				// font-size: 24px;

				margin-top: 0px!important;
			`}
	
			&[type='radio'] {
        display: none;
      }

      /* background: ${(props) => props.theme.lighterTwo}; */
      /* box-shadow: 5px 5px 7px ${(props) => props.theme.darkerOne},
				-2px -2px 4px ${(props) => props.theme.lighterOne}; */
    }
    button {
      /* background: black; */
      width: 100%;
      display: block;
      color: ${(props) => props.theme.darkerTwo};
      font-size: 1.2rem;
      border: none;
      border-radius: 30px;
      margin-top: 18px;
      padding: 8px 18px;
      text-align: center;
      background: linear-gradient(
        135deg,
        ${(props) => props.theme.halfUp},
        ${(props) => props.theme.primary}
      );
      box-shadow: 5px 5px 7px ${(props) => props.theme.darkerTwo},
        -2px -2px 4px ${(props) => props.theme.lighterTwo};
    }
    .underbutton-msg {
      text-align: center;
      font-size: 0.8rem;
      margin-top: 12px;
      display: block;
    }
  }
`;
