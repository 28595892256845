import React, { useEffect } from "react";
import { useAppContext } from "../../context/appProvider";

/**
 * A component for pushing events into the GTM datalayer. Uses component did mount to ensure a window dom element is available.
 * Further work is needed on this component to make it more powerful
 */

const DataLayer = (props) => {
  const { event, lang } = props;
  const ctx = useAppContext();

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: event,
      language: lang,
      authenticated: ctx?.state?.user?.authenticated,
      // pageType: pageType,
      // destination: destination
    });
  }, []);
  return <></>;
};
export default DataLayer;
