import React from "react";
import Styled from "./styled";
import { Button } from "../UI/Buttons";
import GroupCard from "./components/GroupCard";
import pathBuilder from "../../utils/pathBuilder";
import { Link } from "gatsby";
import { Tag } from "../UI/index";
import moment from "moment";
import { pushImpressionClick } from "../../utils/tagManager";

const isPast = (date) => {
  const startingDate = moment(date);
  const now = moment().add(-1, "days");
  return now >= startingDate ? true : false;
};

const LiveOnlineCourseGridItem = ({ node }, lang) => {
  const {
    title,
    studentLevel,
    price,
    slug,
    groups,
    node_locale,
    contentful_id,
    subtitle,
    teachers,
    __typename,
    location,
  } = node;

  const trackClick = () => {
    pushImpressionClick({
      productList: [
        {
          id: contentful_id,
          name: title,
          category: `live-course`,
        },
      ],
      location,
    });
  };

  return (
    <Styled.Wrapper
      as={Link}
      to={pathBuilder(node_locale, __typename, slug)}
      onClick={trackClick}
    >
      <Styled.Container>
        {studentLevel && studentLevel.length > 0 && (
          <Styled.Overline>
            Best if you are in:
            <div>
              {studentLevel.map((studentLvl, index) => (
                <Tag.Primary key={index}>{studentLvl.level}</Tag.Primary>
              ))}
            </div>
          </Styled.Overline>
        )}
        <Styled.Header>
          <h2>{title}</h2>
          <p>{subtitle}</p>
          <span>
            <span className="overline">Price:</span> {price}€
          </span>
          <br />
          <span className="overline">Teacher:</span>
          {teachers.title}
          <br />
        </Styled.Header>

        <Styled.Footer>
          {groups &&
            groups.length > 0 &&
            groups
              .filter((group) => !isPast(group.starting))
              .map((group) => <GroupCard group={group} key={group.id} />)}
        </Styled.Footer>
        <Button>View details & groups</Button>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default LiveOnlineCourseGridItem;
