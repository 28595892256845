import React, { useEffect, useState } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/Layout/Layout";
import pathBuilder from "../../utils/pathBuilder";
import ContentfulRenderer from "../../components/System/ContentfulRenderer";
import BreadCrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import SecondaryNavigation from "./components/SecondaryNavigation";
import Styled from "./styled";
import { BlogPostSchema } from "../../components/Seo/Schemas";
import SocialShare from "../../components/SocialShare";
import UiLayout from "../../components/UI/Layout";
import Banner from "../../components/banner";
import RelatedBooksComponent from "../../components/RelatedBooks";

const LanguagePill = (props) => {
  const [videoId, set_videoId] = useState("");
  // class languagePill extends Component {

  useEffect(() => {
    if (document.querySelector('[id^="video_"]')) {
      const videoId = document.querySelector('[id^="video_"]').id;
      set_videoId({ videoId });
    }
  }, []);

  const {
    title,
    content,
    node_locale,
    contentful_id,
    __typename,
    slug,
    seoDescription,
    difficulty,
    createdAt,
    seoTitle,
    outro,
    relatedExercises,
    updatedAt,
    titleBeautiful,
    intro,
  } = props.data.pill;

  const lastDate = new Date(updatedAt);

  // constructing environment.
  const environment = {
    lang: node_locale,
    id: contentful_id,
    path: pathBuilder(node_locale, __typename, slug),
    theme: "orange",
    altHrefLang: [
      {
        lang: node_locale,
        path: pathBuilder(node_locale, __typename, slug),
      },
      {
        lang: props.data.altPill.node_locale,
        path: pathBuilder(
          props.data.altPill.node_locale,
          props.data.altPill.__typename,
          props.data.altPill.slug,
        ),
      },
    ],
    seo: {},
  };
  if (seoDescription) {
    environment.seo.description = seoDescription;
  }
  if (seoTitle) {
    environment.seo.title = seoTitle;
  } else {
    environment.seo.title = title;
  }

  // Constructing secondary menu, depending on present elements.
  const secondaryMenu = [];

  if (intro && intro.raw) {
    secondaryMenu.push({
      title: "Intro",
      link: "intro",
    });
  }
  if (content) {
    secondaryMenu.push({
      title: "Theory",
      link: "explanations",
    });
  }
  if (relatedExercises) {
    secondaryMenu.push({
      title: "Exercises",
      link: "exercises",
    });
  }
  if (outro && outro.raw) {
    secondaryMenu.push({
      title: "Next steps",
      link: "next",
    });
  }

  let displayTitle = title;
  if (titleBeautiful) {
    displayTitle = titleBeautiful;
  }

  return (
    <Layout environment={environment}>
      <BlogPostSchema
        path={environment.path}
        title={displayTitle}
        description={seoDescription}
        updatedAt={updatedAt}
        createdAt={createdAt}
        imageUrl={props.data.defaultFbImage.publicURL}
      />
      <Styled.ConceptIntro>
        <Styled.ConceptIntroContainer>
          <h1>{displayTitle}</h1>
          {secondaryMenu ? <SecondaryNavigation menu={secondaryMenu} /> : null}
          {intro || seoDescription ? (
            intro ? (
              <ContentfulRenderer
                json={intro}
                lang={environment.lang}
                id="intro"
              />
            ) : (
              <p id="intro">{seoDescription}</p>
            )
          ) : null}
          <BreadCrumbs
            lang={environment.lang}
            type={__typename}
            path={environment.path}
            title={title}
            meta={"Last edit: " + lastDate.toDateString()}
          />
          <SocialShare cta={"Share&Save"} environment={environment} />
        </Styled.ConceptIntroContainer>
      </Styled.ConceptIntro>
      <UiLayout.Content>
        <UiLayout.BodyContainer>
          <UiLayout.Main id="grammar-content">
            <Styled.SectionContainer>
              <h2 className="header">Learn the theory</h2>
              <ContentfulRenderer
                json={content}
                lang={environment.lang}
                id="explanations"
              />
            </Styled.SectionContainer>

            {relatedExercises
              ? relatedExercises.map((item, index) => (
                <div key={index}>
                  <Styled.SectionContainer id="exercises">
                    <div>
                      <h2 className="header">Practice with exercises</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.content.childMarkdownRemark.html,
                        }}
                      />

                      <h2 className="header">Solutions</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.answers.childMarkdownRemark.html,
                        }}
                      />
                    </div>
                  </Styled.SectionContainer>
                </div>
              ))
              : null}
            {outro ? (
              <Styled.SectionContainer id="next">
                <h2 className="header">Some ideas to keep learning</h2>
                <ContentfulRenderer
                  json={outro}
                  lang={environment.lang}
                  id="explanations"
                />
              </Styled.SectionContainer>
            ) : null}
          </UiLayout.Main>

          <UiLayout.Aside>
            <Banner />
          </UiLayout.Aside>
        </UiLayout.BodyContainer>
      </UiLayout.Content>
      <Styled.BookSectionWrapper>
        <UiLayout.Content>
          <div className="header-row">
            <h3>Related practice books!</h3>
            <Link to="/en/shop">See all books</Link>
          </div>
        </UiLayout.Content>

        <RelatedBooksComponent level={difficulty} environment={environment} />
      </Styled.BookSectionWrapper>
    </Layout>
  );
};

export default LanguagePill;

export const langPillQuery = graphql`
  query languagePillQuery($lang: String!, $id: String!, $altLang: String!) {
    pill: contentfulPill(
      contentful_id: { eq: $id }
      node_locale: { eq: $lang }
    ) {
      title
      slug
      node_locale
      contentful_id
      seoTitle
      difficulty
      seoDescription
      updatedAt
      createdAt
      titleBeautiful
      content {
        raw
        references {
          ... on ContentfulAsset {
            id
            title
            file {
              url
              fileName
              contentType
            }
            contentful_id
            spaceId
            locale: node_locale
            __typename
            gatsbyImageData
          }
          ... on ContentfulBook {
            id
            title
            slug
            sku
            price
            numberOfPages
            level
            contentful_id
            contentLanguage
            fileIdSnipcart
            productId
            __typename

            format
            image {
              gatsbyImageData(width: 300, layout: CONSTRAINED)
              file {
                fileName
                url
                contentType
              }
            }
            spaceId
            subtitle
          }
          ... on ContentfulPill {
            id
            title
            titleBeautiful
            slug
            node_locale
            __typename

            difficulty
            contentful_id
            spaceId
          }
        }
      }
      intro {
        raw
        references {
          ... on ContentfulPill {
            id
            title
            slug
            contentful_id
          }
          ... on ContentfulPodcastEpisode {
            id
            contentful_id
            slug
            title
          }
        }
      }
      outro {
        raw
        references {
          title
          slug
          contentful_id
        }
      }
      relatedExercises {
        title
        content {
          childMarkdownRemark {
            html
          }
        }
        answers {
          childMarkdownRemark {
            html
          }
        }
      }
      __typename
    }
    altPill: contentfulPill(
      contentful_id: { eq: $id }
      node_locale: { eq: $altLang }
    ) {
      title
      slug
      node_locale
      __typename
    }
    defaultFbImage: file(relativePath: { eq: "TDOA-SOCIAL-DEFAULT.png" }) {
      publicURL
    }
  }
`;
