import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout/Layout";
import pathBuilder from "../../utils/pathBuilder";
import ContentfulRenderer from "../../components/System/ContentfulRenderer";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import RelatedContent from "../../components/RelatedContent/RelatedContent";
import { BlogPostSchema } from "../../components/Seo/Schemas";
import {
  ArticleIntroContainer,
  ArticleIntro,
  ArticleImageContainer,
} from "./styled.jsx";
import UiLayout from "../../components/UI/Layout";
import Banner from "../../components/banner";

const BlogPost = (props) => {
  const {
    content,
    node_locale,
    slug,
    seoDescription,
    seoTitle,
    title,
    image,
    contentful_id,
    __typename,
    relatedBooks,
    relatedArticles,
    createdAt,
    updatedAt,
    description,
    relatedPills,
  } = props.data.Article;

  const lastDate = new Date(updatedAt);

  const environment = {
    lang: node_locale,
    id: contentful_id,
    path: pathBuilder(node_locale, __typename, slug),
    // imgUrl: image.localFile.url,
    theme: "pink",
    altHrefLang: [
      {
        lang: node_locale,
        path: pathBuilder(node_locale, __typename, slug),
      },
      {
        lang: props.data.altArticle.node_locale,
        path: pathBuilder(
          props.data.altArticle.node_locale,
          props.data.altArticle.__typename,
          props.data.altArticle.slug,
        ),
      },
    ],
  };
  const seo = {};
  if (seoDescription) {
    seo.description = seoDescription;
  }
  if (seoTitle) {
    seo.title = seoTitle;
  } else {
    seo.title = title;
  }
  environment.seo = seo;

  let articleMetadescription = seo.description;
  if (description && description.childMarkdownRemark) {
    articleMetadescription = description.childMarkdownRemark.excerpt;
  }

  return (
    <Layout environment={environment}>
      <BlogPostSchema
        path={environment.path}
        title={title}
        description={articleMetadescription}
        updatedAt={updatedAt}
        createdAt={createdAt}
        imageUrl={image.main.images.fallback.src}
      />
      <ArticleIntro>
        <ArticleIntroContainer>
          <h1>{title}</h1>
          {description && (
            <div
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html,
              }}
            />
          )}
          <Breadcrumbs
            lang={environment.lang}
            type={__typename}
            path={environment.path}
            title={title}
            meta={"Last edit: " + lastDate.toDateString()}
          />
        </ArticleIntroContainer>
      </ArticleIntro>
      {image ? (
        <ArticleImageContainer>
          <GatsbyImage
            image={image.main}
            alt={image.title + " - " + image.description}
          />
        </ArticleImageContainer>
      ) : null}
      <UiLayout.Content>
        <UiLayout.BodyContainer>
          <UiLayout.Main>
            <ContentfulRenderer
              json={content}
              // references={references}
              lang={environment.lang}
            />
            {relatedBooks || relatedArticles || relatedPills ? (
              <RelatedContent
                lang={environment.lang}
                relatedBooks={relatedBooks}
                relatedArticles={relatedArticles}
                relatedPills={relatedPills}
              />
            ) : null}
          </UiLayout.Main>
          <UiLayout.Aside>
            <Banner />
          </UiLayout.Aside>
        </UiLayout.BodyContainer>
      </UiLayout.Content>
    </Layout>
  );
};

// blogPost.propTypes = {
// 	data: PropTypes.object.isRequired,
// }

export const articleQuery = graphql`
  query blogPostQuery($lang: String!, $id: String!, $altLang: String!) {
    Article: contentfulArticle(
      contentful_id: { eq: $id }
      node_locale: { eq: $lang }
    ) {
      title
      slug
      node_locale
      contentful_id
      seoTitle
      seoDescription
      updatedAt
      createdAt
      description {
        childMarkdownRemark {
          html
        }
      }
      content {
        raw
        references {
          __typename
          ... on ContentfulArticle {
            id
            contentful_id
            title
            node_locale
            title
          }
          ... on ContentfulPodcastEpisode {
            id
            contentful_id
            title
            node_locale
          }
          ... on ContentfulPill {
            id
            title
            contentful_id
          }
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(width: 1000)
            __typename
            file {
              url
            }
          }
        }
      }
      image {
        main: gatsbyImageData(width: 1200, layout: CONSTRAINED)
        title
        description

        # url
      }
      __typename
    }
    altArticle: contentfulArticle(
      contentful_id: { eq: $id }
      node_locale: { eq: $altLang }
    ) {
      title
      slug
      node_locale
      __typename
    }
  }
`;
export default BlogPost;
