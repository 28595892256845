import styled from "styled-components";
import mixins from "../../theme/mixins";

const Button = styled.button`
  ${mixins.colored.primary};
  display: inline-block;
  padding: 10px 20px;
  border-radius: 10px;
  color: white;
  text-shadow: 1px 1px 2px ${(props) => props.theme.darkerOne};
  font-weight: 700;
  text-decoration: none !important;
`;

const ButtonOverPrimary = styled(Button)`
  ${mixins.shadowed.primary.small}
`;

const ButtonOverDefault = styled(Button)`
  ${mixins.shadowed.default.small}
`;
const ButtonDisabled = styled(Button)`
  ${mixins.shadowed.default.small}
  background: ${(props) => props.theme.default_halfUp};
  color: ${(props) => props.theme.default_darkerOne};
  text-shadow: none;
  border: none;
`;
const ButtonContrastOverDefault = styled(Button)`
  ${mixins.colored.contrast}
  ${mixins.shadowed.defaultLight.small}
  text-shadow: 1px 1px 2px ${(props) => props.theme.contrast_darkerOne};
  color: white;
  & span {
    color: white;
  }
`;
const ButtonContrastOverPrimary = styled(Button)`
  ${mixins.colored.contrast}
  ${mixins.shadowed.default.small}
  text-shadow: 1px 1px 2px ${(props) => props.theme.contrast_darkerOne};
`;

const ButtonSecondaryPrimary = styled(Button)`
  background: ${(props) => props.theme.default_lighterTwo};
  box-shadow: none;
  border: none;
  color: ${(props) => props.theme.default_darkerOne};
  text-shadow: 1px 1px 2px ${(props) => props.theme.default_lighterTwo};
`;

const allStyled = {
  PrimaryPrimary: ButtonOverPrimary,
  PrimaryDefault: ButtonOverDefault,
  ContrastPrimary: ButtonContrastOverPrimary,
  ContrastDefault: ButtonContrastOverDefault,
  SecondaryPrimary: ButtonSecondaryPrimary,
  Disabled: ButtonDisabled,
};

export default allStyled;
