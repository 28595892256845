/* eslint-disable prettier/prettier */
import React from "react";
import Layout from "../../components/Layout/Layout";
import LoginButton from "../../components/LoginButton";
import * as Styled from "./user.styled";
import { useAppContext } from "../../context/appProvider";
import PageIntro from "../../components/PageIntro/PageIntro";
import { ContainerWide, Container, Row, Column } from "../../components/System/Grids";
import BookGridItem from "../../components/BookGridItem/BookGridItem";
import Button from '../../components/UI/Button'
import pathBuilder from "../../utils/pathBuilder";
import { Link } from "gatsby";
import { GatsbyImage } from 'gatsby-plugin-image'
import { getData } from "../../utils/helpers"
// import CourseGridItem from "../../components/CourseGridItem";

const UserTemplate = (props) => {
  const ctx = useAppContext();
  const { state, dispatch, logout, getAccessTokenSilently } = ctx;
  const { environment, data } = props;
  const { books, groupCourses } = data;

  // const purchasedVideoCourses = state?.user?.purchased
  //   ? videoCourses?.edges?.filter((vidCourse) =>
  //     state?.user?.purchased?.find(
  //       (bought) => bought.productId === vidCourse.node.contentfulid,
  //     ),
  //   )
  //   : false;
  const getInvoice = async (id) => {
    const token = await getAccessTokenSilently();
    window.location.href = `${process.env.GATSBY_BACKEND_URL}/invoices/getInvoice/${id}?token=${token}`
  }

  const purchasedBooks = state?.user?.purchased
    ? books?.edges?.filter((book) =>
      state?.user?.purchased?.find(
        (bought) => bought.productId === book.node.productId,
      ),
    )
    : false;
  const purchasedGroupCourses = state?.user?.purchased
    ? groupCourses?.edges?.filter((courseGroup) =>
      state?.user?.purchased?.find(
        (bought) => courseGroup.node.contentful_id === bought.productId
      ),
    )
    : false

  const userInvoices = state?.user?.invoices;
  const seo = {};
  seo.title = `My account at The Dutch Online Academy`;
  environment.seo = seo;
  if (state?.user?.authenticated) {
    return (
      <Layout environment={environment}>
        <PageIntro>
          <h1 style={{ margin: "0" }}>My account</h1>
          <br />
          <span><small>{state.user.user.email}</small></span>

          <Styled.HorizontalNavigation>
            <li>
              <a href="#group-courses">My courses</a>
            </li>
            <li>
              <a href="#books">My books</a>
            </li>

          </Styled.HorizontalNavigation>

        </PageIntro>

        {/* <ContainerWide>
          <h2>Video courses</h2>
          <Row>
            {purchasedVideoCourses &&
              purchasedVideoCourses?.map(({ node }) => {
                return (
                  <CourseGridItem
                    slug={node.slug}
                    subtitle={node.subtitle}
                    title={node.title}
                    intro={node.intro}
                    price={node.price}
                    cover={node.covers[0]}
                    id={node.contentfulid}
                    key={node.contentfulid}
                  />
                );
              })}
          </Row>
        </ContainerWide> */}

        <ContainerWide id="books">

          <h2>My books</h2>
          {purchasedBooks && purchasedBooks.length > 0 ?
            <Row>
              {purchasedBooks.map(({ node }, index) => {
                return (
                  <BookGridItem
                    key={index}
                    title={node.title}
                    price={node.price}
                    subtitle={node.subtitle}
                    level={node.level}
                    productId={node.productId}
                    studentLevel={node.studentLevel}
                    numberOfPages={node.numberOfPages}
                    fileIdSnipcart={node.fileIdSnipcart}
                    image={node.image}
                    contentful_id={node.contentful_id}
                    slug={pathBuilder(
                      node.node_locale,
                      node.__typename,
                      node.slug,
                    )}
                    lang={environment.lang}
                    urlSnipcart={
                      environment.lang === "en" ? "/en/shop" : "/shop"
                    }
                  />
                );
              })}
            </Row>
            :
            <Row>
              <Column>
                <h3>You do not have any book</h3>
                <p>Here you would see all the books you purchased, and you would be able to download the latest version of them.</p>
                <Link to="/en/shop">
                  Go get some
                </Link>
              </Column>
            </Row>

          }
        </ContainerWide>


        <ContainerWide id="group-courses">
          <h2>My Courses</h2>
          <Row>
            {purchasedGroupCourses ?
              purchasedGroupCourses?.map(({ node }) => {
                return (
                  <Styled.BoughtGroupCard key={node.id} className="c-purchased-course-grid-item">
                    <GatsbyImage className="purchased-course-grid-item__image" image={node.live_online_courses[0].cover.main} alt={`${node.live_online_courses[0].title}-cover`} />
                    <div className="purchased-course-grid-item__body">

                      <h4>{node.live_online_courses[0].title}</h4>
                      <p>Group: {node.title}</p>
                      <span>Starting: {node.starting}</span>
                    </div>

                  </Styled.BoughtGroupCard>
                );
              }) : (
                <Column>
                  <h3>You haven&apos;t subscribed to any course</h3>
                  <p>Here you would see all the group courses.</p>
                  <Link to="/en/live-courses">
                    Go get some
                  </Link>
                </Column>)
            }
          </Row>
        </ContainerWide>



        {userInvoices && (


          <ContainerWide id="invoices">
            <h2>My invoices</h2>
            {userInvoices && userInvoices.length > 0 && (<Row>
              {userInvoices.map((invoice) => {
                return (

                  <Styled.InvoiceRow className="row" key={invoice.id}>

                    <span>{invoice.id}</span>

                    <Button.PrimaryDefault
                      onClick={() => getInvoice(invoice.id)}>
                      Download
                    </Button.PrimaryDefault>

                  </Styled.InvoiceRow>

                );
              })}
            </Row>)
            }
          </ContainerWide>
        )}




        <ContainerWide>

          <Row>
            <button onClick={() => {
              dispatch({
                action: "logout", payload: window.location.origin
              });
              logout();
            }}>
              Log out
            </button>
          </Row>
        </ContainerWide>

      </Layout >
    );
  } else {
    return (
      <Layout environment={environment}>
        <PageIntro>
          <h1>
            Hello! <br />
            Please verify your email to access to all your books, courses and invoices!
          </h1>
        </PageIntro>
        <Container>
          <Row align="center">

            <LoginButton type="giant" location="user-page" message="Authenticate email" />
          </Row>
        </Container>
        <br />
      </Layout>
    );
  }
};

export default UserTemplate;
