const theme = {
  colorPrimary: "#FC4C01",
  colorSecondaryDark: "#133D67",
  colorSecondaryLight: "#5EA3E8",
  colorSecondarySuperLight: "#ACDBFF",
  colorContrast: "#F47312",
  colorSystemDarkest: "#1D343C",
  colorSystemDarker: "#C3C3CA",
  colorSystemLight: "#ECEEF5",
  colorSystemWhite: "#FFFFFF",
  fontHeaders: "'Quicksand', sans-serif",
  fontBody: "'Quicksand', sans-serif",

  colorBlue: "#1EA0F2",
  colorOrange: "#FC4C01",
  colorGreen: "#74B022",
  colorYellow: "#EABB0C",
  colorPurple: "#54457F",
  colorPink: "#E43F5F",

  fontBaseSize: "18px",
  fontMobileBaseSize: "16px",
  fontH1Size: "40px",
  fontH2Size: "27px",
  fontH3Size: "22px",
  fontOverlineSize: "13px",
  fontMiniSize: "10px",
  fontSubtitleSize: "18px",
  fontPLineHeight: "1.6",
  contentNarrowMaxWidth: "620px",
  contentMaxWidth: "780px",
  contentWideMaxWidth: "1200px",
  gridGutter: "32px",
  halfGutter: "16px",
  headingHeight: "68px",
  helloBarHeight: "68px",
  animationsTransition: 250,
};

export { theme };
