const HeaderConfig = {
  indexPath: {
    nl: "/",
    en: "/en",
  },
  mainMenu: {
    nl: [
      // {
      // 	title: "Blog",
      // 	address: "/blog",
      // },
      {
        title: "Group Courses",
        address: "/en/live-courses",
      },
      {
        title: "Podcast",
        address: "/podcast",
      },
      {
        title: "Grammatica",
        address: "/nederlandse-grammatica",
      },
      {
        title: "Pdfs",
        address: "/shop",
      },
      // {
      // 	title: `Private lessons`,
      // 	address: `/private-lessons`,
      // },
      // {
      //   title: "Video Courses",
      //   address: "/en/courses",
      // },
    ],
    en: [
      // {
      // 	title: "Blog",
      // 	address: "/en/blog",
      // },
      {
        title: "Group Courses",
        address: "/en/live-courses",
      },
      {
        title: "Podcast",
        address: "/en/podcast",
      },

      {
        title: "Dutch Grammar",
        address: "/en/dutch-grammar",
      },
      {
        title: "Pdfs",
        address: "/en/shop",
      },
      // {
      // 	title: `Private lessons`,
      // 	address: `/en/private-lessons`,
      // },
      // {
      //   title: "Courses",
      //   address: "/en/courses",
      // },
    ],
  },
  socialMenu: {
    nl: [
      {
        title: "Facebook",
        address: "https://facebook.com/thedutchonlineacademy",
      },
      {
        title: "Instagram",
        address: "https://instagram.com/thedutchonlineacademy",
      },
      {
        title: "YouTube",
        address: "https://www.youtube.com/channel/UCrtDGwx9EBPs17sYy5OOvUA",
      },
    ],
    en: [
      {
        title: "Facebook",
        address: "https://facebook.com/thedutchonlineacademy",
      },
      {
        title: "Instagram",
        address: "https://instagram.com/thedutchonlineacademy",
      },
      {
        title: "YouTube",
        address: "https://www.youtube.com/channel/UCrtDGwx9EBPs17sYy5OOvUA",
      },
    ],
  },
};

module.exports = HeaderConfig;
