import React from "react";
import styled from "styled-components";
import { theme } from "../../theme/variables";
import MEDIA from "../../theme/helpers/mediaTemplates";

const HeroSectionContainer = styled.div`
  width: 100%;
  /* background-color: ${theme.colorPrimary}; */
  color: black;
  font-weight: 300;
  font-size: 0.8rem;
  background-color: gray;
  color: white;
  padding-top: 30px;
  ${MEDIA.PHONE`
		padding-top: 20px;
	`}
  a {
    /* display: block; */
    padding: 12px 16px;
    border-radius: 8px;
    font-family: ${theme.fontHeaders};
    text-transform: uppercase;
    border: none;
    font-weight: 700;
    line-height: 1;
    text-align: center;
  }
  &.primary {
    background-color: ${theme.colorPrimary};
    color: white;
  }
  &.tiny {
    background-color: white;
    color: ${theme.colorSystemDarkest};
    a {
      background-color: ${theme.colorPrimary};
      color: white;
      font-size: 1.2rem;
    }
  }

  &.green {
    background-color: ${theme.colorGreen};
    color: white;
    a {
      background-color: white;
      color: black;
    }
  }
  position: relative;
`;
const Content = styled.div`
  max-width: ${theme.contentMaxWidth};
  padding: ${theme.gridGutter};
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: left;
  justify-content: space-between;
  box-sizing: border-box;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  p,
  a,
  h1,
  h2,
  h3,
  h4,
  span,
  div,
  img {
    color: white;
    margin-bottom: 32px;
    display: inline-block;
  }
  p {
    text-align: left;
  }

  div,
  img {
    max-width: 100%;
    /* box-sizing: border-box; */
  }

  .col-md-6 {
    width: 50%;
    padding: 0 ${theme.gridGutter};

    ${MEDIA.PHONE`
            width: 100%;
        `}
  }
  .col-md-12 {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-evenly;
  }
  .card {
    width: 28%;
    padding: ${theme.halfGutter};
    display: inline-block;
    box-shadow: 1px 1px 8px #33333344;
    margin-top: 40px;
    h1,
    h2,
    h3,
    p {
      color: ${theme.colorSystemDarkest};
    }
    ${MEDIA.PHONE`
            width: 90%;
        `}
  }
  iframe {
    max-width: 100%;
  }
`;

class HeroSection extends React.Component {
  constructor() {
    super();
    this.contentContainer = React.createRef();
    this.state = {
      width: 800,
      height: 182,
    };
  }

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    var viewHeight = window.innerHeight - 85;
    if (this.contentContainer && this.contentContainer.current) {
      var contentHeight = this.contentContainer.current.clientHeight;
      let minHeight = contentHeight;
      if (viewHeight > contentHeight) {
        minHeight = viewHeight;
      }
      this.setState({ height: minHeight });
    }
  }

  /**
   * Add event listener
   */
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    const { content, extraContent } = this.props;
    let theme = "secondary";
    if (extraContent && extraContent.theme) {
      theme = extraContent.theme;
    }
    let heroStyle = {
      minHeight: this.state.height,
    };
    if (theme === "tiny") {
      heroStyle = {
        minHeight: "auto",
      };
    }

    return (
      <HeroSectionContainer className={theme} style={heroStyle}>
        <Content ref={this.contentContainer}>
          <div
            dangerouslySetInnerHTML={{
              __html: content.childMarkdownRemark.html,
            }}
          />
        </Content>
      </HeroSectionContainer>
    );
  }
}

export default HeroSection;
