import React from "react";
import HeaderConfig from "../../../data/HeaderConfig";
import Close from "../../assets/icons/Close.svg";
import styled from "styled-components";
import { theme } from "../../theme/variables";
import { Link } from "gatsby";
import pathBuilder from "../../utils/pathBuilder";

const SideNavigationContainer = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  min-height: 100%;
  right: 0px;
  background-color: ${theme.colorSystemLight};
  z-index: 9;
  padding: ${theme.halfGutter};
  flex-wrap: wrap;
  flex-direction: column;
  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }
  .primarySideMenu {
    padding-left: ${theme.gridGutter};
    height: 400px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-evenly;

    li {
      display: block;
    }
    a {
      font-family: ${theme.fontHeaders};
      font-size: ${theme.fontH4Size};
      border-bottom: 2px solid ${(props) => props.theme.primary};
      width: auto;
      font-weight: 700;
      font-size: 1.5rem;
    }
  }

  .secondarySideMenu {
    li {
      display: inline-block;
      text-transform: uppercase;
      font-size: ${theme.fontOverlineSize};
      padding-right: ${theme.halfGutter};
    }
    a {
      color: ${theme.colorSecondaryDark};
    }
  }

  .sideNavBottomContent {
    padding-left: ${theme.gridGutter};
    section {
      margin-top: ${theme.halfGutter};
    }
    .languagepicker {
      font-size: ${theme.fontOverlineSize};
      text-transform: uppercase;
      color: ${theme.colorSecondaryDark};
    }
  }

  .sideNote {
    position: absolute;
    left: -${theme.halfGutter};
    top: ${theme.gridGutter};
    text-align: left;
    transform-origin: top left;
    text-transform: uppercase;
    color: ${(props) => props.theme.darkerTwo};
    font-size: ${theme.fontOverlineSize};
    letter-spacing: 2px;
    width: 100%;
    white-space: nowrap;
    transform: translateX(100%) rotateZ(90deg);
    opacity: 0;
  }

  /* animations */
  transform: translateX(100%);
  transition: 0.2s ease-out;
  .sidenavClose {
    position: absolute;
    left: ${theme.halfGutter};
    top: ${theme.halfGutter};
    g {
      fill: ${(props) => props.theme.darkerTwo};
      color: ${(props) => props.theme.darkerTwo};
    }
  }
  .sideNote {
    opacity: 0;
    transition: 2s 1s;
  }
  .primarySideMenu {
    li {
      opacity: 0;
    }
    li:nth-child(1) {
      transition: 0.2s 0.3s;
    }
    li:nth-child(2) {
      transition: 0.6s 0.3s;
    }
    li:nth-child(3) {
      transition: 1s 0.3s;
    }
    li:nth-child(4) {
      transition: 1.4s 0.3s;
    }
    li:nth-child(5) {
      transition: 1.7s 0.3s;
    }
    li:nth-child(6) {
      transition: 2s 0.3s;
    }
  }
  .sideNavBottomContent {
    opacity: 0;
    transition: 2.3s 0.3s;
  }
  &.open {
    transform: translateX(0);
    .sideNote {
      opacity: 1;
    }
    .primarySideMenu {
      li {
        opacity: 1;
      }
    }
    .sideNavBottomContent {
      opacity: 1;
    }
  }
`;

const SideNavigation = (props) => {
  const lang = props.lang;
  const sideNavigationClasses = props.classes;
  const onClickFunction = props.onClickFunction;
  const { navMenu } = props;
  const socialMenu = HeaderConfig.socialMenu[lang];

  return (
    <SideNavigationContainer
      className={sideNavigationClasses}
      id="side-navigation-container"
    >
      <Close onClick={onClickFunction} className="sidenavClose" />
      <ul className="primarySideMenu">
        {navMenu.map((link, index) => (
          <li key={index}>
            {link.internalLink && link.internalLink.slug ? (
              <Link
                to={pathBuilder(
                  lang,
                  "ContentfulPages",
                  link.internalLink.slug,
                  link.internalLink.reservedPaths,
                )}
                activeClassName="active"
              >
                {link.title}
              </Link>
            ) : (
              <a href={link.externalUrl}>{link.title}</a>
            )}
          </li>
        ))}
      </ul>

      <div className="sideNavBottomContent">
        <section>
          <span className="miniText">Follow us</span>
          <br />
          <ul className="secondarySideMenu">
            {socialMenu.map((navItem) => (
              <li key={navItem.title}>
                <a href={navItem.address}>{navItem.title}</a>
              </li>
            ))}
          </ul>
        </section>
      </div>
    </SideNavigationContainer>
  );
};

export default SideNavigation;
