import React, { useState } from "react";
import { useAppContext } from "../../../context/appProvider";
import { postData } from "../../../utils/helpers";

const DownloadComponent = (props) => {
  const ctx = useAppContext();
  const { getAccessTokenSilently } = ctx;
  const [isLoading, set_isLoading] = useState(false);
  const [linkUrl, set_linkUrl] = useState("");

  const requestLink = async () => {
    try {
      set_isLoading(true);
      const token = await getAccessTokenSilently();
      const response = await postData({
        url: `${process.env.GATSBY_BACKEND_URL}/media/serve`,
        data: { productId: props.productId },
        token,
      });
      set_linkUrl(response.link);
      // window.open(response.link, "_blank").focus();
      window.location.href = response.link;
      set_isLoading(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({ e });
    }
  };

  return (
    <>
      {isLoading ? (
        <button>Loading</button>
      ) : (
        <>
          {linkUrl ? (
            <a href={linkUrl}>Download file</a>
          ) : (
            <button onClick={() => requestLink("")}>Download file</button>
          )}
        </>
      )}
    </>
  );
};

export default DownloadComponent;
