const slugify = require("slugify");

// Array of reserved URLS - Will null work?
const reservedUrls = {
  index: {
    en: null,
    nl: null,
  },
  shop: {
    en: "shop",
    nl: "shop",
  },
  exams: {
    en: "test-your-dutch",
    nl: "test-je-nederlands",
  },
  grammar: {
    en: "dutch-grammar",
    nl: "nederlandse-grammatica",
  },
  articles: {
    en: "blog",
    nl: "blog",
  },
  "group-courses": {
    en: "live-courses",
    nl: "live-courses",
  },
  podcasts: {
    en: "podcast",
    nl: "podcast",
  },
  cart: {
    en: "cart",
    nl: "cart",
  },
  user: {
    en: "user",
    nl: "user",
  },
  "private-lessons": {
    en: "private-lessons",
    nl: "private-lessons",
  },
  "video-courses": {
    en: "courses",
    nl: "courses",
  },
};

// Every automated page url will come from here!
const urlPaths = {
  ContentfulCategory: {
    en: "category",
    nl: "category",
  },
  ContentfulPill: {
    en: "grammar",
    nl: "grammar",
  },
  ContentfulPages: {
    en: "page",
    nl: "pagina",
  },
  ContentfulBook: {
    en: "books",
    nl: "books",
  },
  ContentfulCourses: {
    en: "courses",
    nl: "courses",
  },
  ContentfulExam: {
    en: "tests",
    nl: "tests",
  },
  ContentfulArticle: {
    en: "articles",
    nl: "articles",
  },
  ContentfulPodcastEpisode: {
    en: "episodes",
    nl: "episodes",
  },
  ContentfulLiveOnlineCourses: {
    en: "group-courses",
    nl: "group-courses",
  },
};

const urlParameters = {
  replacement: "-", // replace spaces with replacement
  remove: /[*+~/.ñ()'"!:@]/g, // regex to remove characters
  lower: true, // result in lower case
};

const mainLang = "nl";

/**
 * Return correct slug from parameters
 * @param {string} locales
 * @param {string} type
 * @param {string} slug
 * @param {string} reservedPaths
 * @return {string}
 */
function pathBuilder(locales, type, slug, reservedPaths = null) {
  let pathOne = "";
  if (locales !== mainLang) {
    pathOne = "/" + locales;
  }

  // exit when we have a reserved path. only add the language key.

  if (reservedPaths && !reservedUrls[reservedPaths][locales]) {
    if (pathOne === "") {
      return "/";
    }
    return pathOne;
  }
  if (reservedPaths) {
    return pathOne + "/" + reservedUrls[reservedPaths][locales];
  }
  const pathTwo = slugify(urlPaths[type][locales], urlParameters);
  const pathThree = slugify(slug, urlParameters);
  return pathOne + "/" + pathTwo + "/" + pathThree;
}

module.exports = pathBuilder;
