const FooterConfig = {
  sharing: {
    en: {
      title: `Sharing is caring`,
      subtitle: `Probably, you know someone who would appreciate this load of free Dutch language resources.`,
      foot: `Don't keep it for yourself!`,
      menu: {
        whatsappTitle: `Share on Whatsapp`,
        facebookTitle: `Share on Facebook`,
        whatsapp: `Hey! Check the Dutch Online Academy to Learn Dutch! https://thedutchonlineacademy.com/?utm_source=footer_share&utm_medium=referral&utm_campaign=share%20on%20whatsapp%20button`,
        emailSubject: `A website with a lot of nice Dutch content`,
        emailBody: `You can check it at http://bit.ly/2ZQjTR3 it's called the Dutch Online Academy`,
        facebookShareLink: `https://thedutchonlineacademy.com/?utm_source=footer_share&utm_medium=referral&utm_campaign=share%20on%20Facebook%20button`,
        twitter: `Share on twitter`,
      },
    },
    nl: {
      title: `Delen is lief.`,
      subtitle: `Waarschijnlijk ken je iemand die al deze gratis informatie over Nederlands zou waarderen.`,
      foot: `Houd het niet voor jezelf!`,
      menu: {
        whatsappTitle: `Share on Whatsapp`,
        facebookTitle: `Share on Facebook`,
        whatsapp: `Hey! I found this site to learn Dutch! https://thedutchonlineacademy.com`,
        emailSubject: `A website with a lot of nice Dutch content`,
        emailBody: `You can check it at https://thedutchonlineacademy.com`,
        twitter: `Share on twitter`,
      },
    },
  },

  copyright: {
    en: `All rights reserved, thedutchonlineacademy 2022`,
    nl: `Alle rechten voorbehouden, thedutchonlineacademy 2022`,
  },
  feedback: {
    en: {
      title: `Do you have any feedback or want to suggest changes? Write us at: `,
      email: `info@thedutchonlineacademy.com`,
    },
    nl: {
      title: `Do you have any feedback or want to suggest changes? Write us at: `,
      email: `info@thedutchonlineacademy.com`,
    },
  },
  titlePrimaryMenu: {
    en: `Learn`,
    nl: `Learn`,
  },
  titleSocialMenu: {
    en: `Connect`,
    nl: `Connect`,
  },
  titleThirdMenu: {
    en: `Practice`,
    nl: `Practice`,
  },
  primaryMenu: {
    en: [
      {
        title: `Dutch Grammar`,
        address: `/en/dutch-grammar`,
      },
      {
        title: `Podcast`,
        address: `/en/podcast`,
      },
      {
        title: `Private Lessons`,
        address: `/en/private-lessons`,
      },
      {
        title: `Group online courses`,
        address: `/en/live-courses`,
      },
      {
        title: `Blog`,
        address: `/en/blog`,
      },
      {
        title: `Exercise PDFs`,
        address: `/en/shop`,
      },
    ],
    nl: [
      {
        title: `Dutch Grammar`,
        address: `/nederlandse-grammatica`,
      },
      {
        title: `Podcast`,
        address: `/podcast`,
      },
      {
        title: `Private Lessons`,
        address: `/private-lessons`,
      },
      {
        title: `Group online courses`,
        address: `/en/live-courses`,
      },
      {
        title: `Blog`,
        address: `/blog`,
      },
      {
        title: `Exercise PDFs`,
        address: `/shop`,
      },
    ],
  },
  thirdMenu: {
    en: [
      // {
      //     title: `Tests`,
      //     address: `/en/language-test`
      // }
    ],
    nl: [
      // {
      //     title: `Tests`,
      //     address: `/language-test`
      // }
    ],
  },
  socialMenu: {
    en: [
      {
        title: `Facebook`,
        address: `https://facebook.com/thedutchonlineacademy`,
      },
      {
        title: `Instagram`,
        address: `https://instagram.com/thedutchonlineacademy`,
      },
      {
        title: `YouTube`,
        address: `https://www.youtube.com/channel/UCrtDGwx9EBPs17sYy5OOvUA`,
      },
    ],
    nl: [
      {
        title: `Facebook`,
        address: `https://facebook.com/thedutchonlineacademy`,
      },
      {
        title: `Instagram`,
        address: `https://instagram.com/thedutchonlineacademy`,
      },
      {
        title: `YouTube`,
        address: `https://www.youtube.com/channel/UCrtDGwx9EBPs17sYy5OOvUA`,
      },
    ],
  },
};

module.exports = FooterConfig;
