import React from "react";
import * as Scroll from "react-scroll";
import { SecondaryNavigationContainer } from "../styled";
import { Fragment } from "react";

class NavItem extends React.Component {
  render() {
    const { link, title, offset = -180 } = this.props.item;
    const ScrollLink = Scroll.Link;

    return (
      <Fragment>
        <li>
          <ScrollLink
            to={link}
            activeClass="active"
            spy={true}
            smooth={true}
            offset={offset}
          >
            {title}
          </ScrollLink>
        </li>
        <div className="separator"></div>
      </Fragment>
    );
  }
}
export default class SecondaryNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerHeight: 0,
      scrollTop: 0,
      fixed: false,
    };
  }
  // handleScroll = ev => {
  // 	let scrollTop = window.scrollY
  // 	// const secondaryNavigation = document.getElementById("secondary-navigation")
  // 	// let distanceToTop = secondaryNavigation.getBoundingClientRect().top

  // 	if (scrollTop > 300) {
  // 		this.setState({ fixed: true })
  // 		// window.removeEventListener("scroll", this.handleScroll)
  // 	} else if (scrollTop < 100){
  // 		this.setState({ fixed: false })
  // 	}
  // }
  componentDidMount() {
    let headerHeight = document.getElementById("header-wrapper").clientHeight;
    this.setState({ headerHeight });
    // window.addEventListener("scroll", this.handleScroll)
  }
  componentWillUnmount() {
    // window.removeEventListener("scroll", this.handleScroll)
  }
  render() {
    // if(this.state.scrollTop>this.state.header)
    const { menu } = this.props;
    const marginTop = this.state.headerHeight;
    let SClasses = "";
    if (this.state.fixed) {
      SClasses = "fixed";
    }

    return (
      <SecondaryNavigationContainer
        id="secondary-navigation"
        style={{ top: marginTop }}
        className={SClasses}
      >
        <ul>
          {menu.map((item, index) => (
            <NavItem item={item} key={index} />
          ))}
        </ul>
      </SecondaryNavigationContainer>
    );
  }
}
