import React from "react";
import styled from "styled-components";
import { Container } from "../../components/System/Grids";
import MEDIA from "../../theme/helpers/mediaTemplates";

const PageIntroContainer = styled.div`
  h1,
  h2,
  h3,
  h4 {
    color: white;
    font-weight: 700;
    display: inline-block;
  }
  span,
  p,
  a {
    color: white;
  }
  a {
    text-decoration: underline;
  }
  h1 {
    font-size: 2rem;
    margin: 2rem 0;
  }
  h2 {
    font-size: 1.4rem;
    margin: 2rem 0;
  }
  ${MEDIA.PHONE`
        h1{
            font-size: 1.6rem;
        }
        h2{
            font-size: 1rem;
        }
        padding: 16px 0;
    `}
  background-color: ${(props) => props.theme.primary};
  padding: 32px 0;
  margin-bottom: 32px;
`;

const PageIntro = (props) => {
  const { children } = props;
  return (
    <PageIntroContainer>
      <Container>{children}</Container>
    </PageIntroContainer>
  );
};

export default PageIntro;
