import React from "react";
import Layout from "../components/Layout/Layout";

const environment = {
  lang: "en",
  path: "/404",
  altHrefLang: [
    {
      lang: "en",
      path: "/404",
    },
  ],

  theme: "orange",
  seo: {
    title: "Page not found",
    description: "The page you are looking for doesn't exist",
  },
};
const NotFoundPage = () => (
  <Layout environment={environment}>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <p>
      Contact us in{" "}
      <a href="mailto:info@thedutchonlineacademy.com">
        info@thedutchonlineacademy.com
      </a>{" "}
      for more info!
    </p>
  </Layout>
);

export default NotFoundPage;
