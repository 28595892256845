import styled from "styled-components";
import mixins from "../../theme/mixins";
import MEDIA from "../../theme/helpers/mediaTemplates";
import { Link } from "gatsby";

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 32px 16px;
  background: white;
  z-index: 99;
  box-shadow: 0px -10px 10px #00000033;
  opacity: 0;
  transition: 0.3s;
  /* transform: translateY(100%); */
  &.visible {
    /* transform: translateY(0%); */
    opacity: 1;
  }
  ${MEDIA.TABLET`
        background-color: rgba(255,255,255,0.98);
        padding: 12px;
        *{
            font-size:0.8rem;
        }
        button{
            padding:10px 32px;
        }
    `}
`;

export const Container = styled.div`
  max-width: 720px;
  margin: auto;
  display: flex;
  align-items: center;
  ${MEDIA.TABLET`
    flex-direction:column;
`}
`;
export const ActionsWrapper = styled.div`
  /* flex-basis: 140px; */
  padding: 12px;
`;
/*     
	h2 {
		margin: 1rem 0 !important;
	}
	.container {
		display: flex;
		overflow: hidden;
		overflow-x: auto;
		width: auto;
		white-space: nowrap;
		flex-wrap: nowrap;
		&::-webkit-scrollbar {
			width: 10px;
			height: 8px;
		}

		&::-webkit-scrollbar-thumb {
			background: rgba(255, 255, 255, 0.2);
			border-radius: 30px;
		}

		&::-webkit-scrollbar-track {
			background: rgba(0, 0, 0, 0);
		}
	}
	${mixins.shadowed.inner.primary}
	border-radius: 14px;
	padding: 22px;
    padding-right:0px;

	${MEDIA.TABLET`

        padding: 12px 0px 12px 24px;
    `} */

export const Testimonial = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 70%;
  border: 1px solid white;
  margin-right: 24px;
  border-radius: 5px;
  /* border:1px solid white; */
  padding: 12px;
  border-radius: 24px;
  margin-bottom: 12px;
  ${mixins.colored.default}
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1rem;
  ${MEDIA.TABLET`
	    padding-bottom: 12px;
        flex-direction:column;
        font-size:0.8rem;
        border-bottom: 1px solid ${(props) => props.theme.primary};

    `}
`;
export const Title = styled.h3`
  display: block;
  margin: 0px;
  color: ${(props) => props.theme.primary}!important;
`;
export const Body = styled.p`
  margin: 0px;
  white-space: normal;
  height: 160px;
  overflow-y: auto;
  font-size: 0.9rem;
  color: ${(props) => props.theme.primary}!important;
  margin-bottom: 1rem;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.primary};
    opacity: 0.5;
    border-radius: 30px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 30px;
  }
  ${MEDIA.TABLET`
	  margin-top:12px;
      font-size:0.8rem;

    `}/* display:block; */
`;
export const Product = styled(Link)`
  color: ${(props) => props.theme.primary}!important;
  text-decoration: underline;
`;
