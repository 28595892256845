import React from "react";
import GlobalStyle from "./Layout.css";
import DataLayer from "../DataLayer/DataLayer";
import Header from "../Header";
import Footer from "../Footer";
import Seo from "../Seo/Seo";
import { ThemeProvider } from "styled-components";
import allThemes from "./theme";
import CookieConsent from "../cookie-consent";

const Layout = (props) => {
  const { children, environment, type = "" } = props;
  const { theme = "blue" } = environment;

  return (
    <ThemeProvider theme={allThemes[theme]}>
      <GlobalStyle />
      <Seo {...props} />
      <Header environment={environment} />
      <DataLayer lang={environment.lang} event="pageView" />
      <div id="main">{children}</div>
      {type !== "landingPage" ? <Footer environment={environment} /> : null}
      <CookieConsent />
    </ThemeProvider>
  );
};

export default Layout;
