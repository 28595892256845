import styled from "styled-components";
// import MEDIA from "../../theme/helpers/mediaTemplates";
// import { theme } from "../../theme/variables";
// import mixins from "../../theme/mixins";
import { Button } from "../UI";

export const LoginButton = styled(Button.ContrastPrimary)`
  padding: ${(props) => (props.type === "giant" ? "10px 24px" : "4px 12px")};
  font-size: ${(props) => (props.type === "giant" ? "20px" : "")};
`;
