import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

const url = "https://thedutchonlineacademy.com";

const Seo = (props) => {
  const { environment } = props.props;
  const {
    lang,
    altHrefLang,
    seo,
    path,
    imgUrl = "https://thedutchonlineacademy.com" +
    props.data.defaultFbImage.publicURL,
  } = environment;
  const { title, description } = seo;

  const showCanonical = path ? true : false;

  altHrefLang.map((item) => {
    if (item.path === "/") {
      item.path = "";
    }
    return null;
  });

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
    >
      {altHrefLang
        ? altHrefLang.map((item, index) => (
          <link
            key={index}
            rel="alternate"
            href={"https://thedutchonlineacademy.com" + item.path}
            hrefLang={item.lang}
          />
        ))
        : null}
      {/* title */}

      {title ? <title>{title}</title> : null}
      {title ? <meta property="og:title" content={title} /> : null}

      {/* Url */}
      {showCanonical ? <meta property="og:url" content={url + path} /> : null}
      {showCanonical ? <link rel="canonical" href={url + path} /> : null}

      {/* Description */}
      {description ? <meta name="description" content={description} /> : null}
      {description ? (
        <meta property="og:description" content={description} />
      ) : null}
      {/* Image */}
      {imgUrl ? <meta property="og:image" content={imgUrl} /> : null}
      {/* Name of the brand */}
      <meta property="og:site_name" content="The Dutch Online Academy" />
      <meta property="og:type" content="article" />

      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Quicksand:400,700&display=swap"
      />
      {/* 
      <link
        rel="stylesheet"
        href="https://cdn.snipcart.com/themes/v3.0.9/default/snipcart.css"
      /> */}
    </Helmet>
  );
};

const SeoComponent = (props) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site: site {
          siteMetadata {
            title
          }
        }
        defaultFbImage: file(relativePath: { eq: "TDOA-SOCIAL-DEFAULT.png" }) {
          publicURL
        }
      }
    `}
    render={(data) => <Seo props={props} data={data} />}
  />
);

export default SeoComponent;
