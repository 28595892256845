import styled from "styled-components";
import mixins from "../../theme/mixins";
import MEDIA from "../../theme/helpers/mediaTemplates";

const Wrapper = styled.div`
  ${mixins.grid.basic};
  padding-bottom: 64px;
`;
const Container = styled.div`
  grid-column: LXL/RXL;
  ${mixins.grid.twoColumns};
  column-gap: 32px;
  justify-content: space-between;
`;
const Header = styled.div`
  grid-column: LXL/RXL;
  ${mixins.colored.default};
  ${mixins.shadowed.default.large};
  border-radius: 38px;
  padding: 32px 16px;
  display: flex;
  justify-content: space-evenly;
  ${MEDIA.PHONE`
		flex-direction:column;
	`}
`;

const UpcomingCourseGridItem = styled.div`
  display: block;
  position: relative;
  padding: 25px 32px;
  .overline {
    position: absolute;
    top: -4px;
  }
  h4 {
    margin: 0px;
    padding-bottom: 12px;
    font-weight: 700;
  }
  margin-bottom: 19px;
  :last-child {
    margin-bottom: 0px;
  }
  ${MEDIA.PHONE`
		padding: 24px 0;
	`}
`;
const allStyled = {
  Wrapper,
  Container,
  Header,
  UpcomingCourseGridItem,
};
export default allStyled;
