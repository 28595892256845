import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Logo from "../../assets/icons/logo.svg";
import LogoMini from "../../assets/icons/logo-mini.svg";
import LogoCompressed from "../../assets/icons/logo-compressed.svg";
import styled from "styled-components";
import MEDIA from "../../theme/helpers/mediaTemplates";

const LogoMain = styled.div`
  .logoPlaceholder {
    display: flex;
    align-items: center;
  }
  .logo.mini {
    max-width: 35px;
    ${MEDIA.TABLET`
					max-width:30px;
				`};
  }
  .shareMenu {
    display: none;
  }
  .logo.big {
    max-width: 250px;
    margin-left: 4px;
    position: relative;
    ${MEDIA.TABLET`
					display:none;
				`};
  }
  .logo.compressed {
    max-width: 250px;
    margin-left: 4px;
    display: block;
    ${MEDIA.MIN_TABLET`
					display:none;
				`};
  }
  .logoPlaceholder.floating {
    .logo.big {
    }
    .shareMenu {
      display: block;
    }
  }
  path,
  polygon,
  g {
    fill: ${(props) => props.theme.darkerTwo};
  }
`;

const LogoComponent = (props) => {
  const [rotation, set_rotation] = useState(0);
  const handleScroll = (ev) => {
    const rotation = window.scrollY;
    set_rotation(rotation);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { indexLink } = props;
  const rotationAngle = rotation;

  return (
    <LogoMain>
      <Link to={indexLink} className={"logoPlaceholder "}>
        <LogoMini
          className="logo mini"
          style={{
            transform: "rotateZ(" + rotationAngle / 8 + "deg)",
          }}
        />
        <Logo className="logo big" />
        <LogoCompressed className="logo compressed" />
      </Link>
    </LogoMain>
  );
};

export default LogoComponent;
