import React from "react";
import { graphql } from "gatsby";
import CoursesTemplate from "../../templates/courseShop";

const environment = {
  lang: "en",
  path: "/en/courses",
  altHrefLang: [
    {
      lang: "en",
      path: "/en/courses",
    },
  ],
  theme: "pink",
  seo: { title: "title", description: "asdfoiasdofi" },
};

const Courses = (props) => {
  return <CoursesTemplate {...props} environment={environment} />;
};
export default Courses;

export const CoursesQuery = graphql`
  query courses {
    courses: allContentfulCourses {
      edges {
        node {
          slug
          title
          intro
          content {
            raw
          }
          lessons {
            title
            id
            contentful_id
          }
          covers {
            gatsbyImageData(layout: CONSTRAINED, width: 400)
          }
        }
      }
    }
  }
`;
